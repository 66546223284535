import axios from 'axios'
import { helpers } from '../_helpers/index'


export const orderService = {
    createServiceOrder,
    uploadPOD,
    getScripOrders,
    getOrderDetails,
    getSecurityByID,
    getServiceOrderDocuments,
    getServiceOrderdDealTermDocuments
}


 



const userId =helpers.setUserId()


function createServiceOrder(data) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/partner/createOrderFromPortal/?callingUserId=${userId}`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function uploadPOD(data) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/partner/uploadClientPaymentProof/?callingUserId=${userId}`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getScripOrders(id) {
    const userId =helpers.setUserId()
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/getIndividualHoldingTransaction/?scripId=${id}/?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getOrderDetails(id) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/service_order/${id}/?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getSecurityByID(id) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/service_orders/security_list?callingUserId=${userId}&service_order_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getServiceOrderDocuments(service_order_id) {
const userId =helpers.setUserId()
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/service_order_document/${service_order_id}/?callingUserId=${userId}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getServiceOrderdDealTermDocuments(service_order_id) {
const userId =helpers.setUserId()
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/partner/deal_term_server_order/${service_order_id}/?callingUserId=${userId}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)
}