import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import loadingSpinner from './../../images/loading-spinner.gif'

import {
  OutlinedInput,
  FormControl,
  FormHelperText,
  Select,
 
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";

const useStyles = makeStyles((theme) => ({
  zeroPadding: {
    padding: 0,
  },
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  cardHeaderMargin: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },
  fileInput: {
    display: "none",
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "5%",
  },
  label: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  popDownBtn: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px 2rem",
    float: "right",
    textTransform:'none',
    // marginTop: theme.spacing(3),
    marginBottom: "2%",
  },

  resendBtnMargin: {
    marginTop: theme.spacing(3),
  },

  inputMargin: {
    marginBottom: theme.spacing(2),
  },

  paddingInput: {
    padding: "0px 0px",
  },
  buttonProgress: {
    marginRight: '4px'
  }

}));




function BankCreateForm(props) {
  const classes = useStyles();
  const [loading , setLoading] = useState(false)
  const ifscCodePattern = RegExp(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/);


  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Container style= {{marginBottom:16,}}>
      <Grid container>

      
      <Formik
        initialValues={{
          beneficiryName: "",
          bankName: "",
          accountNumber: "",
          accountType: "",
          ifscCode: "",
          podCopy: "",
          primaryAccount: false,
        }}
        validationSchema={Yup.object().shape({
          beneficiryName: Yup.string().required("Required"),
          bankName: Yup.string().required("Required"),
          accountNumber: Yup.string().required("Required"),
          accountType: Yup.string().required("Required"),
          ifscCode: Yup.string()
            .matches(ifscCodePattern, "Enter Valid IFSC Code")
            .required("Required"),
            podCopy: Yup
            .mixed()
            .required("A file is required")
            .test(
              "fileFormat",
              "Unsupported Format",
              value => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
              "fileSize",
              "Maximum file size is 2MB",
              value => value && value.size < 2097152
            ) 
        })}
        onSubmit={(values) => {
          setLoading(true)
          props.formSubmit(values)
            
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Beneficiary Name*"

                id="beneficiryName"
                name="beneficiryName"
                onBlur={handleBlur}
                onChange={handleChange}
                // placeholder="Beneficiry Name"
                fullWidth
                value={capitalizeFirstLetter(values.beneficiryName)}
                error={Boolean(touched.beneficiryName && errors.beneficiryName)}
                helperText={touched.beneficiryName && errors.beneficiryName}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Bank Name*"
                id="bankName"
                name="bankName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Bank Name"
                fullWidth
                value={capitalizeFirstLetter(values.bankName)}
                error={Boolean(touched.bankName && errors.bankName)}
                helperText={touched.bankName && errors.bankName}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Account Number*"
                id="accountNumber"
                name="accountNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Account Number*"
                fullWidth
                value={values.accountNumber}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                inputProps={{ maxLength: 20 }}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>


              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Account Type*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="accountType"
                  // className={classes.inputMargin}
                  style={{marginBottom:8}}
                  label="*Account Type"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={Boolean(touched.accountType && errors.accountType)}
                  helperText={touched.accountType && errors.accountType}
                  name="accountType"
                  value={values.accountType}
                  placeholder="Account Type"
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem key="accountType" value="SAVINGS">
                    Saving
                  </MenuItem>
                  <MenuItem key="accountType" value="CURRENT">
                    Current
                  </MenuItem>
                </Select>
                <FormHelperText style={{ color: "#F44336" ,position:"absolute",top:"53px"}}>
                  {errors.accountType &&
                    touched.accountType &&
                    errors.accountType}
                </FormHelperText>
              </FormControl>
              <ErrorFocus/>

              <TextField
              style={{marginTop:24}}
                className={classes.inputMargin}
                variant="outlined"
                label="IFSC CODE*"
                id="ifscCode"
                name="ifscCode"
                
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="IFSC Code"
                fullWidth
                value={values.ifscCode?.toUpperCase()}
                error={Boolean(touched.ifscCode && errors.ifscCode)}
                helperText={touched.ifscCode && errors.ifscCode}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
                
              />
              <ErrorFocus/>

             
<div>
                <Typography className={classes.label}>POD Copy*(Passbook,Canceled Check,Bank Details)</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="podCopy"
                  fullWidth={true}  
                  error={touched.podCopy && Boolean(errors.podCopy)}
                  helperText={touched.podCopy ? errors.podCopy : ""}
                  onChange={(e) => {
                    setFieldValue("podCopy", e.currentTarget.files[0]);
                  }}
                  type="file"
                />

                <FormHelperText style={{ color: "#F44336",paddingLeft:16 }}>
                  {touched.podCopy ? errors.podCopy : ""}
                </FormHelperText>
              </div>
              <ErrorFocus/>

              <FormControlLabel
                control={
                  <Checkbox
                    error={Boolean(
                      errors.primaryAccount && touched.primaryAccount
                    )}
                    helperText={errors.primaryAccount && touched.primaryAccount}
                    name="primaryAccount"
                    value={values.primaryAccount}
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Primary A/C"
              />
              <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                {errors.primaryAccount &&
                  touched.primaryAccount &&
                  errors.primaryAccount}
              </FormHelperText>

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="outlined"
              >
                 {loading === false ? "Add" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress}/> Add </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      </Grid>
    </Container>
  );
}

export default BankCreateForm;
