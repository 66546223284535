import React, { useEffect, useState } from "react";
import { Typography ,Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Page from "./../../components/Page";

import { useParams,useNavigate } from "react-router-dom";
import NormalCard from "./../../components/Cards/NormalCard";
import { userService } from "./../../_services/users";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "./../../store/bugs";
import DematAccountData from "./../../container/Profile/DematAccount";
import BankAccountData from "../Profile/BankAccount";
import CopytoClipboard from "./../../components/ButtonComponents/CopytoClipboard";
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal";
import TableStyle from './../../components/Tables/Theme'
import { ThemeProvider } from "@material-ui/core/styles";



const ShowUserDetails = (props) => {
  const  theme = props.authReducer.theme
  const useStyles = makeStyles((theme) => ({
    hdrlblbtn: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(0),
      alignItems: "center",
      marginBottom: "1%",
    },
    individual: {
      marginTop: theme.spacing(2),
    },
    labelname: {
      color: theme.palette.typography.color,
      [theme.breakpoints.up(576)]: {
        width: "22%",
      },
      width: "32%",
      textAlign: "end",
      fontWeight: "500",
    },
    details: {
      // width: "68%",
      color: theme.palette.typography.color,
      marginLeft: theme.spacing(2),
    },
    detail: { 
      marginTop:"1rem",
      display:"flex",
      marginLeft: theme.spacing(2),
    },
    formgrgroup: {
      display: "flex",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    btndocuments: {
      display: "flex",
      alignItems: "center",
      marginLeft: "16px",
    },
    btnprimary: {
      borderRadius: "12px 12px 12px 0px",
      marginRight: theme.spacing(2),
      textTransform: "capitalize",
    },
    reactSpinner: {
      position: "absolute",
      height: "100%",
      width: "100%",
      background: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  
    iconpdf: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(-1),
    },
    editbutton: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
    },
  
    first_letter: {
      fontSize: "0.87rem",
      marginTop: "3px",
      "&:first-letter": {
        textTransform: "capitalize",
      },
    },
    iconClass: {
      cursor: "pointer",
    },
  }));
  const classes = useStyles();


  let params = useParams();
  const [userBasicInfo, setUserBasicInfo] = useState();
  const userID = params.id
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const navigate = useNavigate();
console.log(props)
 
  const getBasicInfo =() =>{
    userService.getUserDetails(userID).then((res) => {
      if (res) {
        if (res.status === 200) {
          setUserBasicInfo(res.data[0]);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {

            setOpenSessionExpiredModal(true);
          } else {
            console.log(res)
          }
        }
      }
    });
   }

  const [hover, setHover] = useState(false);
  const [userBasicInfomation, setUserBasicInfomation] = useState("");

  let text = "";
  const onHover = () => {
    setHover(true);
  };

  useEffect(() => {
    getBasicInfo()
  }, [])
  
  const name = userBasicInfo
    ? `${userBasicInfo.first_name} ${userBasicInfo.last_name}`
    : null;
    const requestId = userBasicInfo?.request_id;

  const handleEditBtn = () => {
    navigate("/app/user-create?mode=edit", {
      replace: true,
      state: { userID: userID, userName: name, requestId: requestId },
    });
  };

  const onLeave = () => {
    setHover(false);
  };
  const [hovers, setHovers] = useState(false);
  const [userBasicInfomations, setUserBasicInfomations] = useState("");
  const onHovers = () => {
    setHovers(true);
  };

  const onLeaves = () => {
    setHovers(false);
  };

 

  return (
    <Page title="Unlistedkart | User details page">

      <div className={classes.hdrlblbtn}>
        <Typography className={classes.heder} variant="h4">
          Users Details
        </Typography>
      <ThemeProvider theme={TableStyle(props.theme)}>
        <Button
          className={classes.editbutton}
          variant="contained"
          color="primary"
          onClick={handleEditBtn}
        >
          Edit
        </Button>
      </ThemeProvider>

      </div>
      <NormalCard title="User Details">
      {userBasicInfo &&  <div className={classes.individual}>
              <div
                className={classes.formgrgroup}
                onMouseEnter={() => {
                  onHover();
                  setUserBasicInfomation(userBasicInfo.first_name);
                }}
                onMouseLeave={onLeave}
              >
                <label className={classes.labelname}>First Name</label>
                <p className={classes.details}>
                  {userBasicInfo && userBasicInfo?.first_name}
                </p>
                {/* {userBasicInfomation === userBasicInfo.first_name && (
                  <CopytoClipboard
                    userBasicInfo={(text = userBasicInfo?.first_name)}
                    state={hover}
                  />
                )} */}
              </div>
              <div
                className={classes.formgrgroup}
                onMouseEnter={() => {
                  onHover();
                  setUserBasicInfomation(userBasicInfo.last_name);
                }}
                onMouseLeave={onLeave}
              >
                <label className={classes.labelname}>Last Name</label>
                <p className={classes.details}>
                  {userBasicInfo && userBasicInfo.last_name}
                </p>
                {/* {userBasicInfomation === userBasicInfo.last_name && (
                  <CopytoClipboard
                    userBasicInfo={(text = userBasicInfo.last_name)}
                    state={hover}
                  />
                )} */}
              </div>
              <div
                className={classes.formgrgroup}
              >
                <label className={classes.labelname}>User Role</label>
                <p className={classes.details}>
                  {userBasicInfo && userBasicInfo.user_role[0].description}
                </p>
              </div>
              <div
                className={classes.formgrgroup}
                onMouseEnter={() => {
                  onHover();
                  setUserBasicInfomation(userBasicInfo.email);
                }}
                onMouseLeave={onLeave}
              >
                <label className={classes.labelname}>Email</label>
                <p className={classes.details}>
                  {userBasicInfo && userBasicInfo.email}
                </p>
                {/* {userBasicInfomation === userBasicInfo.email && (
                  <CopytoClipboard
                    userBasicInfo={(text = userBasicInfo.email)}
                    state={hover}
                  />
                )} */}
              </div>
              <div
                className={classes.formgrgroup}
                onMouseEnter={() => {
                  onHovers();
                  setUserBasicInfomations(userBasicInfo.contact_no);
                }}
                onMouseLeave={onLeaves}
              >
                <label className={classes.labelname}>Phone</label>
                <p className={classes.details}>
                  {userBasicInfo && userBasicInfo.contact_no}
                </p>
                {/* {userBasicInfomations === userBasicInfo.contact_no && (
                  <CopytoClipboard
                    userBasicInfo={(text = userBasicInfo.contact_no)}
                    state={hovers}
                  />
                )} */}
              </div>

          <div
            className={classes.formgrgroup}
            onMouseEnter={() => {
              onHover();
              setUserBasicInfomation(userBasicInfo?.pan);
            }}
            onMouseLeave={onLeave}
          >
            <label className={classes.labelname}>PAN</label>
            <p className={classes.details}>
              {userBasicInfo && userBasicInfo?.pan.toUpperCase()}
            </p>
            {/* {userBasicInfomation === userBasicInfo?.pan &&  (
                  <CopytoClipboard
                    userBasicInfo={(text = userBasicInfo?.pan)}
                    state={hover}
                  />
                )} */}
          </div>
         {userBasicInfo.reporting_user_name && <div
            className={classes.formgrgroup}
          >
            <label className={classes.labelname}>Reporting User</label>
            <p className={classes.details}>
              {userBasicInfo && userBasicInfo?.reporting_user_name}
            </p>
          </div>}

        </div>}
      </NormalCard>
      <DematAccountData theme={theme} userID={userID} edit={false} />
      <BankAccountData theme={theme} userID={userID} edit={false} />

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(ShowUserDetails);