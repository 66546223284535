import axios from 'axios'
import { helpers } from './../_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const securityService = {
    getSecurity,
    getSecurityByID
};
 
const userId =helpers.setUserId()



function getSecurity() {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/security?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)  
}


function getSecurityByID(id) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/security/${id}?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

