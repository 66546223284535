import React, { useState } from 'react'
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from "@material-ui/core/Tooltip";


function CopytoClipboard({ userBasicInfo, state }) {
  const [icon, setIcon] = useState(true);
  const [open, setOpen] = useState(false);
  function selectText(info) {
    // var elem = document.createElement("textarea");
    // document.body.appendChild(elem);
    // elem.value = info;
    // elem.select();
    // document.execCommand("copy");
    // document.body.removeChild(elem);
    navigator.clipboard.writeText(userBasicInfo);
    setIcon(false)
    setOpen(true);
  }



  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (

    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onMouseLeave={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Copied"
    >
      <IconButton
        color="primary"
        style={{
          marginLeft: "10px",
          width: "25px",
          height: "20px",
        }}
      >
        {state ? (
          <>
            {
              userBasicInfo == null || userBasicInfo == "" ? (<></>) : (<><FileCopyIcon
                style={{
                  display: icon ? "block" : "none", fontSize: "15px"
                }}
                onClick={() => {
                  selectText(userBasicInfo);
                }}
              />
                <CheckIcon
                  style={{
                    display: icon ? "none" : "block", fontSize: "15px"
                  }}
                />
              </>)
            }
          </>
        ) : (
          ""
        )}
      </IconButton>
    </Tooltip>
  )
}

export default CopytoClipboard