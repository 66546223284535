import React, { useEffect, useState } from "react";
import { Typography,Divider, Grid , ListItemText,ListItemIcon,List,ListItem,IconButton,ListItemSecondaryAction} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Page from "./../../components/Page";
import MUIDataTable from "mui-datatables";
import { useParams } from "react-router-dom";
import NormalCard from "./../../components/Cards/NormalCard";
import { orderService } from "./../../_services/orders";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "./../../store/bugs";
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PDF from "./../../images/PDF.png";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ModalCentered from "./../../components/Modals/ModalCentered";
import ImageRotate from "../../components/Image/ImageRotate";
import { getLookUpDataService } from "../../_services/lookUp";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  hdrlblbtn: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },
  individual: {
    marginTop: theme.spacing(2),
  },
  labelname: {
    color:theme.palette.typography.color,
    [theme.breakpoints.up(576)]: {
      width: "22%",
    },
    width: "32%",
    textAlign: "end",
    fontWeight: "500",
  },
  details: {
    // width: "68%",
    color:theme.palette.typography.color,

    marginLeft: theme.spacing(2),
  },
  detail: {
    marginTop: "1rem",
    display: "flex",
    marginLeft: theme.spacing(2),
  },
  pricebar: {
    display: "flex",
    paddingTop: theme.spacing(1),
    fontWeight: "500",
    color: "rgb(49, 75, 143)",
    marginBottom: theme.spacing(-1),
  },
  pricelabel: {
    [theme.breakpoints.down(960)]: {
      width: "50%",
      textAlign: "left",
    },
    width: "80%",
    textAlign: "end",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnright: {
    textAlign: "end",
  },
  formgrgroup: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  btndocuments: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },
  btnprimary: {
    borderRadius: "12px 12px 12px 0px",
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
  },
  reactSpinner: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  iconpdf: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  editbutton: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },
  unCheck: {
    fontSize: "20px",
    marginBottom: theme.spacing(-0.5),
    color: "#f44336",
  },
  first_letter: {
    fontSize: "0.87rem",
    marginTop: "3px",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  iconClass: {
    cursor: "pointer",
  },
  download:{
    marginLeft:'8px',
    cursor:'pointer'
  }
}));

const OrderDetails = (props) => {
  const classes = useStyles();

  let params = useParams();
  const [serviceOrderData, setServiceOrderData] = useState();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(false);
  const [show, setShow] = useState(false);
  const orderId = params.id;
  const [openSessionExpiredModal, setOpenSessionExpiredModal] =
    React.useState(false);
    const [orders, setOrders] = useState([]);
    const themeMode = props.authReducer.theme ==='dark' ? true: false
    const [documentData, setDocumentData] = React.useState([]);
    const [dealTermFileData, setDealTermFileData] = useState("");
    const [imageDialog, setImageDialog] = React.useState(false);
    const [imageUrl, setImageUrl] = useState("");


    const handleModalClose = () => {
      setImageUrl(false)
      setImageDialog(false);
    };
  
  const getMuiTheme = () =>
    createTheme({
      overrides: {

        MUIDataTable:{
          responsiveBase:{
            scrollbarWidth:'none',
            scrollbarColor: "#888 #f0f0f0",
            height: "83vh",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: "5px",
              // display:'none',
              height:'2px'
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor:themeMode? "#252d3a": '#3e3e3e',
              // display:'none'
            },
          }
        },
        MuiAppBar: {
            colorPrimary: {
                boxShadow: 'none',
                backgroundColor: 'none',
                color: themeMode? '#fff': '#324C90',
                marginTop: '4px',
                marginBottom: '-15px'
            }
        },
        MuiToolbar:{
          root:{
            color:themeMode ? "#fff" : ''
          }
        },
        MUIDataTableHeadCell: {
          root:{
            whiteSpace: 'nowrap',
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : '',
            "&.sortActive":{
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : ''
            },
          },
            // fontFamily:"system-ui !important"
          
        
        
          hintIconAlone: {
            marginLeft: "4px",
            marginTop: "4px",
            color: " gray",
            fontFamily:"system-ui "
          },
        },
        MUIDataTableHead:{
          root:{
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : ''
          },
         
        },
        MUIDataTableHeadCell:{
          fixedHeader:{
            backgroundColor:themeMode ?"#252d3a" : '',
            whiteSpace: "nowrap",
          },
          sortActive:{
            color:themeMode ? "#fff" : ''
          }
        },
        MuiButton:{
          root: {
          fontFamily:"system-ui",
          color:themeMode ? "#fff" : '',
          },
          label:{
            color:themeMode ? "#fff" : ''
          }
      },
      MUIDataTableFilter:{
        root:{
          backgroundColor:themeMode ? "#1c1c1c" : '',
          color:themeMode ? "#fff" : ''
        },
      },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: "nowrap",
            color:themeMode ? "#fff" : '',

          },
        },
        MuiInputBase:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiFormLabel:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSvgIcon:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSelect:{
          root:{
            color:themeMode ? "#fff" : '',
          },
          MuiSvgIcon:{
            color:themeMode ? "#fff" : '',

          },
          MuiSvgIcon:{
            root:{
              color:themeMode ? "#fff" : '',
            }
          }
        },
        MuiTypography:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiTableCell: {
          root: {
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "16px",
            paddingRight: "16px",
            fontFamily:"system-ui ",
            color:themeMode ? "#fff" : ''
            
          },
          head:{
            color:themeMode ? "#fff" : ''

          }
        },
        MuiTabs: {
          indicator: {
            background:themeMode ? "#fff" : "#324C90",
          },
        },
        MuiBox: {
          root: {
            padding: 0,
          },
        },
        MuiPaper: {
          elevation4: {
              boxShadow: "0px 0px 3px lightGrey",
              marginTop: '16px',
              marginBottom: '12px',
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : '',
          }
      },
      MuiMenu:{
        paper:{
          backgroundColor:themeMode ?"#1d1d1d" : '',
          color:themeMode ? "#fff" : '',
        }
      },
        MUIDataTablePagination: {
          tableCellContainer: {
            padding: 0,
          },
          MUIDataTableBodyCell: {
            stackedCommon: {
              width: "100% !important",
            },
          },
        },
      },
    });

  const getBasicInfo = () => {
    orderService.getOrderDetails(orderId).then((res) => {
      if (res) {
        if (res.status === 200) {
          setServiceOrderData(res.data);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          } else {
            console.log(res);
          }
        }
      }
    });
  };

  const getUrl =(async (id,name)=>{
    await getLookUpDataService.getObjectUrl(id).then(async (res) => {
      if (res.data.success) {
      const fileUrl = res.data.data;
      downloadImage(fileUrl,name)
  }})
  })

  

  const handleFileUrl = (value) => {
    setShow(true)
    getLookUpDataService.getObjectUrl(value.fileid).then((res) => {
      if (res.data.success) {
        setUrl({
          url: res.data.data,
          isPdf:value.deal_term_file_type ==="application/pdf" ||value.deal_term_file_type ===".pdf" ? true : false,
          title:value.document_name
        });
      }
    });
  };

  const getSecurityData = () => {
    setLoading(true)
    orderService.getSecurityByID(orderId).then((res) => {
      if (res) {
        if (res.status === 200) {
    setLoading(false)
          setOrders(res.data);
          // RMCommission()
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  };

  const getSignedUrl = (url) => {
    setImageDialog(true)
    getLookUpDataService.getKycUrl(url).then((res)=>{
      setImageUrl(res.data.data);
    })
  }

  useEffect(() => {
    getBasicInfo();
    getSecurityData();
    getDealTermDocument()
    getServiseOrderDocuments()
  }, []);

  let RMCommission =orders.length>=1 && new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format((orders[0].price - orders[0].commision)*orders[0].quantity );

  const downloadImage = (imageSrc, name) => {
    fetch(imageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const SecurityColumn = [
    {
      name: "security_id",
      label: "securityID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_order_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "is_security_mapped",
      label: "Map",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "slno",
      label: "Sl No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1
        },
      },
    },
    {
      name: "security_name",
      label: "Scrip Name",
    },
    {
      name: "isin",
      label: "ISIN",
    },
    {
      name: "quantity",
      label: "Quantity",
    },
    {
      name: "price",
      label:
        serviceOrderData?.order_type_code === "SELL"
          ? "Sold Price"
          : "Buy/Client Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          var floatValue = Number(value).toFixed(3);
          return floatValue
        },
      },
    },

    {
      name: "order_supplies",
      label: "Order Supplies",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "commision",
      label: "RM Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          var floatValue = Number(value).toFixed(3);
          return floatValue
        },
      },
    },
    {
      name: "consolidated_price",
      label: "Consideration Amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          var floatValue = Number(value).toFixed(3);
          var buyquantity = Number(
            tableMeta.tableData[tableMeta.rowIndex].quantity
          ).toFixed(3);
          
          return ((floatValue * buyquantity).toFixed(3))
        },
      },
    },
  ];

  const getServiseOrderDocuments = () => {
    orderService
      .getServiceOrderDocuments(orderId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setDocumentData(res.data);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDealTermDocument = () => {
    orderService
      .getServiceOrderdDealTermDocuments(orderId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const file = new Blob([res.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            setDealTermFileData(fileURL);
            console.log(fileURL)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      });
  };

  function checkFileType(url) {
    var extension = url
      ?.split(".")
      .pop()
      .toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].indexOf(extension) >= 0) {
      return "image";
    } else if (["pdf"].indexOf(extension) >= 0) {
      return "pdf";
    } else {
      return "unknown";
    }
  }

  const imageType =
  serviceOrderData && checkFileType(serviceOrderData.client_payment_proof);
  return (
    <Page title="Unlistedkart | UserdetailsPage">
      <div className={classes.hdrlblbtn}>
        <Typography className={classes.heder} variant="h4">
          Order Details
        </Typography>
      </div>
      <NormalCard title="Basic info">
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Type</label>
            <p className={classes.details}>
              {serviceOrderData?.order_type_code == "SELL" ? "Sell" : "Buy"}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Transaction Type</label>
            <p className={classes.details}>
              {serviceOrderData?.transaction_type_code == "INTERMEDIATE"
                ? "Intermediate"
                : "Direct"}
            </p>
          </div>
          {serviceOrderData?.transaction_type_code === "INTERMEDIATE" &&
          serviceOrderData?.channel_partner !== null ? (
            <>
              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>Channel Partner</label>
                <p className={classes.details}>
                  {serviceOrderData?.channel_partner}
                </p>
              </div>
              <div className={classes.formgrgroup}>
                <label className={classes.labelname}>
                  Channel Partner PAN No
                </label>
                <p className={classes.details}>
                  {serviceOrderData?.channel_partner_pan}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>
              {serviceOrderData?.order_type_code === "SELL"
                ? "Investor"
                : "Vendor"}
            </label>
            <p className={classes.details}>
              {serviceOrderData?.order_type_code === "SELL"
                ? serviceOrderData?.investor
                : serviceOrderData?.vendor}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>
              {serviceOrderData?.order_type_code === "SELL"
                ? "Investor PAN No"
                : "Vendor PAN No"}
            </label>
            <p className={classes.details}>
              {serviceOrderData?.order_type_code === "SELL"
                ? serviceOrderData?.investor_pan?.toUpperCase()
                : serviceOrderData?.vendor_pan?.toUpperCase()}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Status</label>
            <p className={classes.details}>
              {serviceOrderData?.order_status_new_display === "Completed"
                ? "Closed"
                : serviceOrderData?.order_status_new_display}{" "}
              {/* {serviceOrderData?.is_purchased_online === true ? <></> : (<span>*/}
              {!serviceOrderData?.order_status_new_display &&
                serviceOrderData?.order_status_code}{" "}
            </p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Lead Owner</label>
            <p className={classes.details}>{serviceOrderData?.lead_owner}</p>
          </div>

          {serviceOrderData?.is_purchased_online ? (
            <></>
          ) : (
            <>
              {serviceOrderData?.co_managed_one && (
                <div className={classes.formgrgroup}>
                  <label className={classes.labelname}>Co Managed 1</label>
                  <p className={classes.details}>
                    {serviceOrderData?.co_managed_one}
                  </p>
                </div>
              )}
              {serviceOrderData?.co_managed_two && (
                <div className={classes.formgrgroup}>
                  <label className={classes.labelname}>Co Managed 2</label>
                  <p className={classes.details}>
                    {serviceOrderData?.co_managed_two}
                  </p>
                </div>
              )}
            </>
          )}

          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>order ID</label>
            <p className={classes.details}>{serviceOrderData?.service_id}</p>
          </div>
        </div>
        <div className={classes.formgrgroup}>
          <label className={classes.labelname}>Role</label>
          <p className={classes.details}>{serviceOrderData?.description}</p>
        </div>
        {(serviceOrderData?.description === "Partner's Client" ||
          serviceOrderData?.description === "Partner's RM") &&
          serviceOrderData?.admin_name && (
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>
                Partner Representative
              </label>
              <p className={classes.details}>
                {serviceOrderData?.admin_name}
                {serviceOrderData?.admin_pan &&
                  ` (${serviceOrderData?.admin_pan})`}
              </p>
            </div>
          )}

        {serviceOrderData?.payment_utr_no && (
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>UTR Number</label>
            <p className={classes.details}>
              {serviceOrderData?.payment_utr_no}
            </p>
          </div>
        )}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Payment Proof</label>
            <div className={classes.details}>
                {serviceOrderData?.is_client_uploaded_payment_proof === true ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={()=>{
                      const url = serviceOrderData?.client_payment_proof.split('amazonaws.com/')
                      const fileName = url?.length >1 ? url[1] : serviceOrderData?.client_payment_proof
                      getSignedUrl(fileName)
                    }}
                  >
                    <ImageIcon/>View
                  </div>
                ) : (
                  <>
                    {" "}
                    <span>Pending</span>
                    <CancelIcon
                      className={classes.unCheck}
                      style={{ marginLeft: "4px" }}
                      color="primary"
                      fontSize="medium"
                    />
                  </>
                )}
              </div>
          </div>
      </NormalCard>
      <NormalCard title="Security Details">
        <div>
          <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
              data={orders}
              columns={SecurityColumn}
              options={{
                search: false,
                print: false,
                pagination: false,
                download: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 5,
                rowsPerPageOptions: [5, 10, 25],
                // responsive: 'scrollMaxHeight',
                selectableRows: false,
                textLabels: {
                  body: {
                    noMatch: loading ? "Loading..." : "",
                  },
                },
              }}
            />
              <div className={classes.pricebar}>
                      <div className={classes.pricelabel}>
                        <Typography variant="body1">
                          Total RM Commission
                        </Typography>
                      </div>
                      <div className={classes.price}>
                          <Typography
                            variant="body1"
                          >
                            {orders.length>=1 ? RMCommission : null}
                          </Typography>
                      </div>
                    </div>
          </ThemeProvider>
        </div>
      </NormalCard>
      <NormalCard
        title="Documents"
      >
        <div className={classes.uploadAlign}>
          <Grid item md={12} sm={12}>
            <List dense={true}>
              <Grid item md={6} sm={12}>
                <ListItem>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        style={{ maxWidth: 300 }}
                        component={"a"}
                        noWrap={false}
                        href={`${dealTermFileData}`}
                        color="primary"
                        target="_blank"
                      >
                        Deal Term
                      </Typography>
                    }
                  ></ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="download"
                      title="Download"
                      onClick={() =>
                        downloadImage(
                          dealTermFileData, `Deal Term ${serviceOrderData.service_id}.pdf`
                        )
                      }
                    >
                        <CloudDownloadIcon   color="primary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
              <Divider />

              {documentData &&
                documentData.map((item, i) => (
                  <Grid container key={i}>
                    <Grid item md={6} sm={12}>
                      <React.Fragment>
                        <ListItem>
                          <ListItemIcon>
                            {(item.deal_term_file_type === "image/jpg" ||
                              item.deal_term_file_type === "image/gif" ||
                              item.deal_term_file_type === "image/png" ||
                              item.deal_term_file_type === "image/jpeg") && (
                              <ImageIcon />
                            )}
                            {item.deal_term_file_type === "application/pdf" && (
                              <PictureAsPdfIcon />
                            )}
                            {item.deal_term_file_type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                              <DescriptionIcon />
                            )}
                          </ListItemIcon>

                          <ListItemText
                            button
                            primary={
                              <Typography
                                style={{ maxWidth: 300,cursor:"pointer" }}
                                component={"a"}
                                noWrap={false}
                                onClick={()=>handleFileUrl(item)}
                                // href={`${item.deal_term_file_url}`}
                                color="primary"
                                target="_blank"
                              >
                                <p>
                                  {" "}
                                  {item.document_name === ""
                                    ? item.deal_term_document_type_description
                                    : item.document_name}
                                </p>
                              </Typography>
                            }

                            // secondary={secondary ? 'Secondary text' : null}
                          />
                         
                            <IconButton
                              edge="end"
                              aria-label="download"
                              title="Download"
                              style={{padding: '0 10px'}}
                              onClick={() =>
                                getUrl(
                                  item.fileid, `${item.document_name === ""
                                  ? item.deal_term_document_type_description
                                  : item.document_name}.${item.deal_term_file_type !== "application/pdf" ?'jpg' : 'pdf'}`
                                )
                              }
                            >
                                <CloudDownloadIcon color="primary" />
                            </IconButton>
                        </ListItem>

                        <Divider />
                      </React.Fragment>
                    </Grid>
                  </Grid>
                ))}
            </List>
          </Grid>
        </div>
        <ModalCentered
          title={url.title ? url.title : "Loading..."}
          fullScreen={true}
          open={show}
          toggle={() => {
            setShow(false)
            setUrl(false)
          }}
          children={
            url.url ? (
              <div style={{ textAlign: "center", position: "relative" }}>
                <Grid container style={{ display: "flex",justifyContent:"center" }}>
                  <Grid item xs={12} md={10}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {url.isPdf ? (
                        <iframe
                          style={{
                            width: "100%",
                            height: "calc(100vh - 100px)",
                          }}
                          src={url.url}
                        />
                      ) : (
                        <ImageRotate alt="cool image" image={url && url.url} />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (<div>
              Loading...
            </div>)
          }
        />
      </NormalCard>
      <ModalCentered
          title={"Payment proof"}
          open={imageDialog}
          fullScreen={true}
          toggle={handleModalClose}
          children={
            imageUrl ?
            ( <div style={{ textAlign: "center" }}>
              {imageType === "image" ? (
                <ImageRotate
                alt="cool image"
                image={imageUrl}/>
              ) : (
                <iframe
                  src={imageUrl}
                  alt=""
                  style={{ width: "100%", height: "70vh" }}
                />
              )}
            </div>):(
              <div style={{ textAlign: "center" }}>
                Loading...
              </div>
            )
          }
        />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(OrderDetails);
