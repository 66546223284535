import React from 'react';
import { Grid, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import {connect} from 'react-redux'


const NormalCard = (props) => {
    console.log(props)

    const useStyles = makeStyles((theme) => ({
    
        root: {    
            '& .MuiCardContent-root':{
                padding:'0px !important'   
            }, 
            position:'relative', 

            // backgroundColor: theme.palette.common.black,
            paddingBottom: theme.spacing(2),        
            marginBottom:theme.spacing(3),  
            // border:props.theme==='dark' && `1px solid #fff`,

        },
    
        editbtn: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            paddingBottom: '4px',
            alignItems: 'center',
            borderBottom: `1px solid ${props.theme==='dark'? '#fff': '#324C90'}`,
            [theme.breakpoints.down('xs')]:{
                padding: theme.spacing(1),
            }
        },
    }));
    const classes = useStyles()

    return (

        <Card className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} >
                        <div className={classes.editbtn}>
                            <div>
                                <Typography style={{fontSize:"1rem", fontWeight:500}} variant='h5' color="primary">{props.title}</Typography>
                                {/* <Typography variant='body2' >{props.subtitle}</Typography> */}
                            </div>
                            {props.btn || null}
                        </div>
                        {props.children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
};

// export default NormalCard;
const mapStateToProps = state => {
    return ({
      force: state, // force state from reducer
      theme: state.authReducer.theme,
    })
  };
  
  export default connect(mapStateToProps)(NormalCard);
