import axios from 'axios'
import { helpers } from './../_helpers'



export const serviceOrderService = {
    getAllClientServiceOrders,
    statusOfServiceOrder
};





function getAllClientServiceOrders(value) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/getAllClientServiceOrders?callingUserId=${userId}&isSelfOrder=${value}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function statusOfServiceOrder(data) {
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/partner/service_order/status?callingUserId=${userId}`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

