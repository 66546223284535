import React, { useState, useEffect } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { connect } from 'react-redux';
import { setToken, loginSuccess,setUserName,setPassword} from './../../store/bugs'
import Page from "./../../components/Page";
import {
  Box,
  Button,
  Container,
  TextField,
  Card,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SnackBar from "./../../components/SnackBar/SnackBar"
import { loginService } from './../../_services/login'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {

  },
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  box:{
    display:"flex",
    flexDirection:"column",
    height:"100%",
    justifyContent:"center"
  },
  cardHeaderMargin: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  popDownBtn: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: 10,
    marginBottom: "10%",
  },
  pageLayout: {
    height: 'calc(100vh - 128px)',
    padding: '0px !important'
  }
}));


const OtpPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  let location = useLocation();  
  const isDisabled = true
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const lableName = "OTP Verification"
  const [resendOtpTime, setResendOtpTime] = React.useState(20);
  const [mobileNumber , setMobileNumber] = useState("")
  const [userData , setUserData] = useState(null)
  const [submit,setSubmit] = useState(true);

  useEffect(() => {
    const resendOtpSec = setTimeout(() => {
      if (resendOtpTime > 0) {
        setResendOtpTime(resendOtpTime - 1);
      }
      else {
        clearTimeout(resendOtpSec);
      }
    }, 1000);
  }, [resendOtpTime]);

  useEffect(()=>{
     setMobileNumber(location.state.mobile)
    if(userData){     
    if(userData.default_role_code==="PARTNER_ADMIN" || userData.default_role_code=== "PARTNER_RELATIONSHIP_MANAGER"){
      console.log('true')
        navigate("/app/users", { replace: true })
      }
      else if(userData.default_role_code==="PARTNER_CLIENT"){
        
        navigate("/app/orders", { replace: true })
      }
      else{
        console.log('false')
      }
    }
  },[userData]) 

  
  const handleOtp = () => {
    if (location.state.mobile ) {
      setMobileNumber(location.state.mobile)
      setResendOtpTime(30)
      loginService.mobileAuthentication(mobileNumber)
      .then( res => {
        if(res.status === 200){
          setSeverity("success")
          setSnackbarTitle("Otp sent")
          setOpen(true);
        }
      })
    }
  }

 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
 
  const setCookiesFunction = async(res)=>{
    cookies.set('userName', res.data.loggedUserDetails.api_username);
    cookies.set('password', res.data.loggedUserDetails.api_password);
    cookies.set('userId', res.data.loggedUserDetails.id);
    return true
  }

  const handleOnSubmit = (values) => {
    if (location.state.mobile ) {
      loginService.verifyMobileOtp(location.state.mobile || null, values.otp).then(async(res) => {         
          if(res){
            console.log(res)
            setSubmit(false)
            if (res.status === 200) {              
              if (res.data.message === "OTP_VERIFIED") {
                const auth = {loggedIn : true, user:res.data.loggedUserDetails}
                props.loginSuccess({auth:auth})
                setUserData(res.data.loggedUserDetails);
                if(res.data.loggedUserDetails.authentication_method ===1){
                  props.setToken({token:res.data.Jwttoken.token})
                  cookies.set('token', res.data.Jwttoken.token, { path: '/' });
                  cookies.set('userId', res.data.loggedUserDetails.id, { path: '/' });
                }else{
                  props.setUserName({ userName: res.data.loggedUserDetails.api_username });
                  props.setPassword({ password: res.data.loggedUserDetails.api_password });
                  cookies.set('userName', res.data.loggedUserDetails.api_username, { path: '/' });
                  cookies.set('password', res.data.loggedUserDetails.api_password,  { path: '/' });
                cookies.set('userId', res.data.loggedUserDetails.id, { path: '/' });
                }
                setSeverity("success")
                setSnackbarTitle("Otp Verified Successfully!")
                setOpen(true); 
              }
            }
            else if(res.status === 400){
              if(res.data.message==="INVALID_OTP"){
                setSeverity("error")
                setSnackbarTitle("Invalid OTP")
                setOpen(true);
              }
            }
            else if (res.status === 401) {
              if(res.data.message==="ACCOUNT_BLOCKED"){
                setSeverity("error")
                setSnackbarTitle("Your account is blocked")
                setOpen(true);
              }
            }
            else if (res.status === 403) {
              if(res.data.message==="Forbidden"){
                setSeverity("error")
                setSnackbarTitle("Invalid login")
                setOpen(true);
              }
            }
          }
        })
    }
  }

  return (
    <>
      <Page addClass={classes.pageLayout} title="Unlistedkar | Login">
        <Box
         className={classes.box}
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                otp: ""
              }}
              validationSchema={Yup.object().shape({
                // otp: Yup.string()
                // .matches(phoneRegex, "Invalid Otp")
                // .required("Enter an OTP sent to your mobile number"),
              })}
              onSubmit={(values) => {

                handleOnSubmit(values)
                setSubmit(true)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column">
                    {/* <header>
                        <img src={logo} alt="logo" className={classes.imageSizes}/>
                </header> */}
                    <Card className={classes.cardBorderRadius}>
                      <Typography
                        className={classes.cardHeaderMargin}
                        variant="h5"
                      >
                        {lableName}
                      </Typography>
                      <Container className={classes.container}>
                        <Typography
                          color="primary"
                          gutterBottom
                          variant="h5"
                          className={classes.marginInputLabel}
                        >
                          Please Enter the OTP
                      </Typography>

                        <TextField
                          error={Boolean(
                            touched.otp && errors.otp
                          )}
                          helperText={touched.otp && errors.otp}
                          label="OTP"
                          name="otp"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          value={values.otp}
                          autoComplete='off'
                          variant="outlined"
                          // labelWidth={0}
                          // onInput = {(e) =>{
                          //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                          // }}
                          inputProps={{ maxLength: 6 }}
                          onInput={(e) => { 
                            e.target.value = e.target.value.replace(/[^0-9]/g, '') 
                            if(e.target.value.length === 6){
                              setSubmit(false)
                            }
                          }}
                          // floatingLabelText="To*"
                          // floatingLabelFixed={true}
                        />
                        <br />
                        <Button
                          color="primary"
                          disabled={submit || values.otp.length < 6}
                          className={classes.popDownBtn}
                          size="medium"
                          type="submit"
                          variant="contained"
                        >
                          Verify
                      </Button>
                        <div className={classes.resendBtnMargin}>
                          {resendOtpTime > 0 ?
                            (<div>
                              <Button onClick={handleOtp}  color="primary" disabled={isDisabled}>Resend otp</Button>
                              <br />
                              <Typography className={classes.seconds} >{`${resendOtpTime} seconds`}</Typography></div>)
                            : <Button onClick={handleOtp} color="primary" disabled={!isDisabled}>Resend otp</Button>
                          }
                        </div>
                      </Container>
                    </Card>
                  </Grid>
                </form>
              )}
            </Formik>
            
          </Container>
        </Box>
      </Page>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </>
  );
};

const dispatchToProps = dispatch => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
  setPassword: (password) => dispatch(setPassword(password)),
  setUserName: (userName) => dispatch(setUserName(userName)),
  
});

export default connect(null, dispatchToProps)(OtpPage);
