import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Page from "../../components/Page";
import {
  Box,
  Button,
  Container,
  Card,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { loginService } from "../../_services/login";
import SnackBar from "../../components/SnackBar/SnackBar";
import FormHelperText from '@material-ui/core/FormHelperText';
import { useNavigate, NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.css";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const useStyles = makeStyles((theme) => ({
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  box:{
    display:"flex",
    flexDirection:"column",
    height:"100%",
    justifyContent:"center"
  },
  cardHeaderMargin: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  loginLink: {
    color: "#3f51b5,",
    fontSize: "13px",
    border: " 1px solid #3f51b5",
    padding: "4px 16px",
    fontWeight: "500",
    height: "29px",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },
  linkNbtn: {
    display: "flex",
    justifyContent: "space-between",
    margin: "-4px 0 40px 0px",
  },
  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  pageLayout: {
    height: "calc(100vh - 128px)",
    padding: "0px !important",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [submit,setSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [country, setCountry] = useState([]);
  const [valueColor, setValueColor] = useState(false)
  const phoneRegex = RegExp(/^[0-9]{12}$/);
  const [state, setState] = useState({
    submitted: false,
    title: "Login",
    buttonText: "Continue",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOnSubmit = (values) => {
    loginService
      .mobileAuthentication(`+${values.mobileNumber}`)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            navigate("/verify-otp", {
              replace: true,
              state: { mobile: `+${values.mobileNumber}` },
              type: "login",
            });
          } else if (res.status === 401) {
            setSubmit(false)
              if (res.data === "REQUIRED_REGISTRATION") {
              setSeverity("error");
              setSnackbarTitle("Please enter registered mobile no");
              setOpen(true);
              } else {
                if(res.data.message==="ACCOUNT_BLOCKED"){
                  setSeverity("error")
                  setSnackbarTitle("Your account is blocked")
                  setOpen(true);
                  
                }
              }
          } else if (res.status === 400) {
            setSubmit(false)
            setSeverity("error");
            setSnackbarTitle("ACCOUNT HAS BEEN SUSPENDED");
            setOpen(true);
          } else if (res.status === 204) {
            setSeverity("error");
            setSnackbarTitle("Mobile no is required");
            setOpen(true);
          } else if (res.status === 403) {
            setSeverity("error");
            setSnackbarTitle("You Don't have Access to Login");
            setOpen(true);
          }
        } else{
          setSeverity("error")
          setSnackbarTitle("Something went wrong")
          setOpen(true);
          setSubmit(false)
        }
      })
      .catch((error) => console.log(error));
  };

  const handleError = (value) => {
    if (value && value.length <= 2) {
        setValueColor(true)
    }
    else if (value.length <= 11) {
        setValueColor(true)
    }
    else {
        setValueColor(false)
    }
  }

  return (
    <Page addClass={classes.pageLayout} title="Unlistedkart | Login">
      <Box
       className={classes.box}
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={{mobileNumber: "",}}
            validationSchema={Yup.object().shape({
              mobileNumber: Yup.string()
                .nullable()
                .matches(phoneRegex, "Enter a valid Mobile Number")
                .required("Mobile Number is required"),
            })}
            onSubmit={(values,) =>{ 
              handleOnSubmit(values)
              setSubmit(true)
            }}
          >
            {({
              errors,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              handleBlur,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column">
                  <Card className={classes.cardBorderRadius}>
                    <Typography
                      className={classes.cardHeaderMargin}
                      variant="h5"
                    >
                      {state.title}
                    </Typography>

                    <Container className={classes.container}>
                      <Typography
                        color="primary"
                        gutterBottom
                        variant="h5"
                        className={classes.marginInputLabel}
                      >
                        Enter your registered mobile number
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <PhoneInput
                            id="mobileNumber"
                            name="mobileNumber"
                            countryCodeEditable={false}
                            // onlyCountries={["in","us","au","sg","hk","ae","gb","nz","ph"]}
                            style={(valueColor ? { border: "1px solid red", borderRadius: "5px" } : { border: "1px solid #314B8F", borderRadius: "5px" })}
                            country={"in"}
                            placeholder="Enter phone number"
                            value={values.mobileNumber}
                            handleBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("mobileNumber", e)
                              
                            }}
                          />
                          <FormHelperText style={{ color: "red" }}>{(errors.mobileNumber && touched.mobileNumber) && errors.mobileNumber}</FormHelperText>  
                        </Grid>
                      </Grid>
                      <br />
                      <div className={classes.linkNbtn}>
                      <Typography variant='body2' > Don’t have an account yet? &nbsp;
                            <Typography
                              variant='body2' 
                              component={LinkBtn}
                              to={`/`}
                              color="primary"
                            >
                              REGISTER.
                            </Typography>
                          </Typography>
                        <Button
                          color="primary"
                          disabled={submit}   
                          size="medium"
                          type="submit"
                          variant="contained"
                          onClick={() => handleError(values.mobileNumber)}
                        >
                          {state.buttonText}
                        </Button>
                      </div>
                    </Container>
                  </Card>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </Page>
  );
};  

export default LoginPage;

