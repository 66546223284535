import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Typography, Box, Button, IconButton } from '@material-ui/core';
import Page from '../../components/Page';
import SessionExpiredModal from "../../components/AlertModal/SessionExpiredModal"
import ModalCentered from "./../../components/Modals/ModalCentered";
import OrderCreateForm from './OrderCreateForm';
import SnackBar from "./../../components/SnackBar/SnackBar";
import { useLocation } from "react-router-dom";
import UploadIcon from './../../images/upload.svg'
import UploadPOD from './UploadPOD';
import { orderService } from "./../../_services/orders";
import { connect } from "react-redux";
import VisibilityIcon from '@material-ui/icons/Visibility';
const IndividualScripOrders = (props) => {
    const location = useLocation()
    const [orderData, setOrderData] = useState([])
    const [loading, setLoading] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [severity, setSeverity] = React.useState("");
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [open, setOpen] = useState(false);
    const [orderId, setOrderId] = useState(null);


    console.log(openModal)


    const uploadPOD = ((values) => {
        console.log()
        setLoading(true)
        const formData = new FormData();
        formData.append('orderid', orderId)
        formData.append('photos', values.podCopy)

        orderService.uploadPOD(formData).then((res) => {
            setLoading(false)
            setOpenModal(false)
            if (res.data.success) {
                setSeverity("success")
                setSnackbarTitle("Uploaded successfully")
                setOpen(true);
                getOrders('get')
            } else {
                setSeverity("error")
                setSnackbarTitle("Upload failed")
                setOpen(true);
            }
        })
    })
    console.log(location.state)
    useEffect(() => {
        getOrders()
    }, [])

    const getOrders = ((value) => {
        !value && setLoading(true);
        orderService.getScripOrders(location?.state).then((res) => {
            setLoading(false)
            console.log(res)
            if (res) {
                if (res.status === 200) {
                    setOrderData(res.data.data[0].holding_transactions)
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true);
                    } else {
                    }
                }
            } else {
                console.log(res)
            }
        })
            .catch((error) => console.log(error));
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const columns = [

        {
            name: "buy_sell_order_id",
            label: "Order Id",
        },
        {
            name: "quantity",
            label: "Qty",
        },
        {
            name: "price_per_share",
            label: "Price per share",
            options: {
                customBodyRender: (value) => {
                    return <Typography>{parse(value)}</Typography>;
                },
            },
        },
        {
            name: "price",
            label: "Total Buy Price",
            options: {
                customBodyRender: (value) => {
                    return <Typography>{parse(value)}</Typography>;
                },
            },
        },
        {
            name: "holding_status",
            label: "Status",
            options: {
                customBodyRender: (value) => {
                    const status = value === "UNDER_PROCESS" ? "Under Process" : "Owned"
                    return <Typography>{status}</Typography>;
                },
            },
        },
        {
            name: "client_payment_proof",
            label: "Payment Proof",
            options: {
                customBodyRender: (value,row) => {
                    console.log(value)
                    return (
                        value ? <Button
                            componet="a"
                            style={{ borderRadius: '100%' }}
                            href={value}
                        >
                            <VisibilityIcon fontSize='small' />
                        </Button> :  <IconButton
                                onClick={() => {
                                    openModalHandler()
                                    setOrderId(row.rowData[0])
                                }}>
                                <img src={UploadIcon} alt="upload" />
                            </IconButton>
                    )
                },
            },
        },
    ];


    const parse = ((data) => parseFloat(data).toFixed(2))

    const openModalHandler = (type) => {
        setTitleModal("Create order");
        setOpenModal(true);
    };

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    return (

        <React.Fragment>
            <Page title="Unlistedkart | Security Order List">

                <MUIDataTable
                    title={
                        <>
                            <Typography variant="h6">
                                {location?.state[0]?.scrip_name}
                            </Typography>
                        </>

                    }
                    data={orderData}
                    headerSort={false}
                    columns={columns}
                    options={{
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        selectableRows: false,
                        download: false,
                        viewColumns: false,
                        print: false,
                        textLabels: {
                            body: {
                                noMatch: loading ?
                                    'Loading...' : 'Sorry, there is no matching data to display'
                            },
                        },
                    }}
                />

            </Page>
            <ModalCentered
                title={"Upload POD Copy"}
                open={openModal}
                toggle={closeModalHandler}
                children={
                    <UploadPOD
                        formSubmit={uploadPOD}
                    />
                }
            />
            <SnackBar
                open={open}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </React.Fragment>

    )

}
const mapStateToProps = state => {
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
};

export default connect(mapStateToProps)(IndividualScripOrders);


