import { createTheme, colors } from "@material-ui/core/styles";
import shadows from "./shadows";
import typography from "./typography";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      dark: "#F4F6F8",
      default: "#fff",
      paper: "#fff",
    },
    typography: {
      color: "#000",
    },
    primary: {
      main: "#314B8F",
    },
    secondary: {
      main: "#757575",
    },
    text: {
      primary: "#2f2f2f",
      secondary: "#424242",
    },
  },
  styleOverrides: {
    MuiInputBase: {
      root: {
        color: "#fff",
      },
    },
    MuiTextField: {
      root: {
        color: "#fff",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },
  shadows,
  typography,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
});

export const darkTheme = createTheme({
  mode: "dark",
  palette: {
    background: {
      dark: "#23313a",
      default: "#fff",
      paper: "#23313a",
    },
    typography: {
      color: "#fff",
    },

    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#757575",
    },
    text: {
      primary: "#2f2f2f",
      secondary: "#424242",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#fff", // Modify the styles here for the root element of the TextField
        "&.MuiTypography-colorTextSecondary":{
          color:"#fff"
        }
      },
    },
    MuiInputAdornment:{
      MuiTypography:{
          color: "#fff",
        }
      
      
    },
    MuiDivider:{
      // root:{
        color: "#fff",
      // }
    },
    MuiTextField: {
      root: {
        '& .MuiInputBase-input': {
          color: '#ffffff', // Change the text color to white
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ffffff', // Change the outline color to white
          },
          '&:hover fieldset': {
            borderColor: '#ffffff', // Change the hover outline color to white
          },
          '&.Mui-focused fieldset': {
            borderColor: '1px solid #ffffff', // Change the focused outline color to white
          },
        },
        '& .MuiInputLabel-outlined': {
          color: '#ffffff', // Change the label color to white
          marginTop: '-10px', // Increase the top padding to prevent overlapping
        },
      },

    },
    MuiSvgIcon:{
      root:{
        color: "#fff",
      }
    },
    MuiCard:{
      root:{
        backgroundColor: "#23313a",
      }
    },
    MuiInputBase:{
      underline: {
      colors:'#fff'
      },
     
    },
    MuiOutlinedInput:{
      root:{
        border:'1px solid #fff !important'
      }
    },
   
    MuiAutocomplete:{
      listbox:{
        color:'#fff',
        backgroundColor:'#0b1a26',
        scrollbarColor: "#888 #f0f0f0",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          width: "2px",
          // display:'none',
          height:'2px'
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:'#fff',
          // display:'none'
        },
        },
     popper:{
      colors:'#fff'
      },
      noOptions:{
        colors:'#fff !important'
      },
     root:{
     
      paper:{
        colors:'#fff'
        }
     }
      
    },
    MuiMenuItem:{
      root:{
        color:'#fff',
      }
    },
    MuiFormLabel: {
      root: {
        color: "#fff", // Modify the styles here for the root element of the TextField
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
          '&.MuiInput-underline:before':{
            borderBottom: "1px solid #fff",
            },
            '&.MuiInput-underline:hover:not(.Mui-disabled):before':{
              borderBottom: "1px solid #fff",
              }
        
      },
     
  },

  MuiButton: {
    
    outlined: {
      color: "#fff !important",
      textTransform:'none',
      border:'1px solid #fff !important'
      // '&.MuiButton-outlined-9002':{
      // }
    },
    contained: {
      color: "#2c2c2c",
      textTransform:'none',
      backgroundColor:'#fff !important'
      // '&.MuiButton-outlined-9002':{
      // }
    },
    
},
component:{
MuiCard:{
  root:{
  backgroundColor:"#2c2c2c !important"
  }
}
},
},

  shadows,
  typography,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
});

//
