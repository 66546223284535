import React, { useEffect } from "react";
import { makeStyles, } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";



import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  OutlinedInput,

  FormHelperText,
  Checkbox,
  Container,

  TextField,

  Typography,
  FormControlLabel,

} from "@material-ui/core";
import loadingSpinner from './../../images/loading-spinner.gif'

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles((theme) => ({

  container: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "5%",
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  popDownBtn: {
    // borderRadius: "0 0 12px 12px",
    textTransform:'none',
    padding: "8px 1rem",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "2%",
  },

  buttonProgress: {
    marginRight: '4px'
  },
  inputMargin: {
    '& .MuiFormHelperText-contained': {
      margin: '4px 0 0'
    }
  }
}));


function UploadPOD(props) {
  const classes = useStyles();


  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];


  return (
    <Container>
      <Formik
        initialValues={{
          podCopy: "",
          amount: props.data.amount,
          utrNumber:props.data.utrNumber
        }}
        validationSchema={Yup.object().shape({
          podCopy: Yup.mixed()
            .required("A file is required")
            .test(
              "fileFormat",
              "Unsupported Format",
              value => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
              "fileSize",
              "Maximum file size is 2MB",
              value => value && value.size < 2097152
            ),
          utrNumber: Yup.string().nullable().required('UTR Number is required'),
          amount: Yup.string().required('Amount is required'),

        })}
        onSubmit={(values) => {
          setLoading(true)
          props.formSubmit(values)

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>


              <div>
                <TextField
                  className={classes.inputMargin}
                  variant="outlined"
                  label="UTR Number*"
                  id="utrNumber"
                  name="utrNumber"
                  onBlur={handleBlur}
                  disabled={props.data.utrNumber !==null}
                  onChange={handleChange}
                  placeholder="UTR Number"
                  fullWidth
                  value={values.utrNumber}
                  error={Boolean(touched.utrNumber && errors.utrNumber)}
                  helperText={touched.utrNumber && errors.utrNumber}
                />
                <TextField
                  style={{ marginTop: 24 }}
                  className={classes.inputMargin}
                  variant="outlined"
                  label="Amount*"
                  id="amount"
                  name="amount"
                  onBlur={handleBlur}
                  disabled
                  // onChange={handleChange}
                  placeholder="Amount"
                  fullWidth
                  value={values.amount}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}

                />
                <Typography className={classes.label}>Upload Proof of delivery* </Typography>
                <OutlinedInput

                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="podCopy"
                  fullWidth={true}
                  error={touched.podCopy && Boolean(errors.podCopy)}
                  helperText={touched.podCopy ? errors.podCopy : ""}
                  onChange={(e) => {
                    setFieldValue("podCopy", e.currentTarget.files[0]);
                  }}
                  type="file"
                />

                <FormHelperText style={{ color: "#F44336" }}>
                  {touched.podCopy ? errors.podCopy : ""}
                </FormHelperText>
              </div>
              <ErrorFocus />


              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="outlined"

              >
                {loading === false ? "Upload" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Upload </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </Container>
  );
}

export default UploadPOD;
