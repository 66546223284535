import axios from 'axios'

import { helpers } from '../_helpers/index'



//default header and api endpoint config
export const loginService = {
    mobileAuthentication,
    verifyMobileOtp,    
    logout,
};

function mobileAuthentication(username) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/mobile/otpauthentication`, JSON.stringify({ username: username}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function verifyMobileOtp(username, otp) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/mobile/otpvalidation_forRm`, JSON.stringify({ username, otp}), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}



function logout() {
    helpers.removeCookies("token")
    helpers.removeCookies("userName")
    helpers.removeCookies("password")
}



