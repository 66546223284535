import React, { useEffect } from "react";
import { makeStyles, } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";



import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  OutlinedInput,
 
  FormHelperText,
  Checkbox,
  Container,
  
  TextField,
 
  Typography,
  FormControlLabel,
 
} from "@material-ui/core";
import loadingSpinner from './../../images/loading-spinner.gif'

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles((theme) => ({
  zeroPadding: {
    padding: 0,
  },
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  cardHeaderMargin: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },
  fileInput: {
    display: "none",
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "5%",
  },
  label: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  popDownBtn: {
    // borderRadius: "0 0 12px 12px",
    textTransform:'none',
    padding: "8px 2rem",
    float: "right",
    textTransform:'none',
    // marginTop: theme.spacing(3),
    marginBottom: "2%",
  },

  resendBtnMargin: {
    marginTop: theme.spacing(3),
  },

  inputMargin: {
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    marginRight:'4px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    
  },
  btnroot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'flex-end'
  },
}));


function DematCreateForm(props) {
  const classes = useStyles();
  

  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Container>
      <Formik
        initialValues={{
          dpWith: "",
          dpId: "",
          clientId: "",
          cmlCopy: "",
          primaryAccount: false,
        }}
        validationSchema={Yup.object().shape({
          dpWith: Yup.string().required("Required")
          .matches("^[A-Za-z 0-9]", "Invalid"),        
          dpId: Yup.string().required("Required")
            .matches("^[A-Za-z0-9]+$", "Invalid")        
            .min(8, "Minimum length should be 8")
            .max(8, "Maximum length should be 8"),
          clientId: Yup.string().required("Required")  
            .matches("^[A-Za-z0-9]+$", "Invalid")        
            .max(8, "Maximum length should be 8 digits")
            .min(8, "Minimum length should be 8 digits"),
          cmlCopy: Yup.mixed()
            .required("A file is required")
            .test(
              "fileFormat",
              "Unsupported Format",
              value => value && SUPPORTED_FORMATS.includes(value.type)
            )
          .test(
            "fileSize",
            "Maximum file size is 2MB",
            value => value && value.size < 2097152
          )
         
        })}
        onSubmit={(values) => {
          setLoading(true)

          props.formSubmit(values)
           
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
         
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="DP With*"
                id="dpWith"
                name="dpWith"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={capitalizeFirstLetter(values.dpWith)}
                error={Boolean(touched.dpWith && errors.dpWith)}
                helperText={touched.dpWith && errors.dpWith}
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="DP ID*"
                id="dpId"
                name="dpId"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={values.dpId}
                error={Boolean(touched.dpId && errors.dpId)}
                helperText={touched.dpId && errors.dpId}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Client ID*"
                id="clientId"
                name="clientId"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.clientId}
                error={Boolean(touched.clientId && errors.clientId)}
                helperText={touched.clientId && errors.clientId}
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <div>
                <Typography className={classes.label}>CML Copy*</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="cmlCopy"
                  fullWidth={true}
                  error={touched.cmlCopy && Boolean(errors.cmlCopy)}
                  helperText={touched.cmlCopy ? errors.cmlCopy : ""}
                  onChange={(e) => {
                    setFieldValue("cmlCopy", e.currentTarget.files[0]);
                  }}
                  type="file"
                />

                <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                  {touched.cmlCopy ? errors.cmlCopy : ""}
                </FormHelperText>
              </div>
              <ErrorFocus />

              <FormControlLabel
                control={
                  <Checkbox
                    error={Boolean(
                      errors.primaryAccount && touched.primaryAccount
                    )}
                    helperText={errors.primaryAccount && touched.primaryAccount}
                    name="primaryAccount"
                    value={values.primaryAccount}
                    id="primaryAccount"
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Set Primary"
              />
              <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                {errors.primaryAccount &&
                  touched.primaryAccount &&
                  errors.primaryAccount}
              </FormHelperText>
              <ErrorFocus />

              <Button
                color="primary"
                disabled={props.spinner}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="outlined"

              >
                {props.spinner === false ? "Add" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress}/> Add </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </Container>
  );
}

export default DematCreateForm;
