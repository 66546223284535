import React from 'react';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import { Navigate } from 'react-router-dom';

//temp components
import LoginPage from './container/Login/Index'
import VerifyOtp from "./container/Login/VerifyOtp"
import UsersData from './container/Users/Index';
import SecurityData from './container/scrips/Index';
import OrderData from './container/Orders/Index';
import UserCreate from './container/Users/UserCreate';
import UserDetails from './container/Users/UserDetails';
import IndividualScripOrders from './container/Orders/IndividualScripOrders';
import ScripDetails from './container/scrips/ScripDetails';
import OrderDetails from './container/Orders/OrderDetails';
const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <UsersData/> },
      { path: 'user-create', element: <UserCreate/> },
      { path: 'scrip-order-list', element: <IndividualScripOrders/> },
      { path: 'scrips', element: <SecurityData /> },
      { path: 'scrip-details/:id', element: <ScripDetails/> },
      { path: 'orders', element: <OrderData /> },
      { path: 'user-details/:id', element: <UserDetails/> },
      { path: 'service-order/:id', element: <OrderDetails/> },

     ]
  },
  {
    path: '/',
    element: <MainLayout />,   
    children: [
    //   { path: 'register', element: <RegisterPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'verify-otp', element: <VerifyOtp /> },
      // { path: 'register', element: <RegisterView /> },
    //   { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
    { path: '*', element: <Navigate to="/" /> }
    ]
  }
];

export default routes;
  
