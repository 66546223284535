import Cookies from 'universal-cookie';

import axios from 'axios'



const cookies = new Cookies();

export const helpers = {
    getCookies,
    removeCookies,   
    checkFileSize,
    setHeader,
    loadScript,
    setUserId,
};

function checkFileSize(size){
    if(size>2097152){       
        return true;
    }
    else {
        return false
    }
}
function getCookies(name){
    return cookies.get(name)
}

function setUserId(){
    const userId = helpers.getCookies("userId")
    
    if(userId){
        return userId
    }
}
function removeCookies(name) {
    return cookies.remove(name)
}
function setHeader() {
    // const data =JSON.parse(localStorage.getItem("persist:root")) 
    // const userData = JSON.parse(data.authReducer)
    const token = helpers.getCookies("token")

    const userName = helpers.getCookies("userName")
    const password =helpers.getCookies("password")
    if(token){
       axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else if(userName && password) {
        axios.defaults.headers.common['Authorization'] = `Basic ${btoa(`${userName}:${password}`)}`;
                // axios.defaults.headers.common['Authorization'] = `Basic ${btoa(`${userName}:${password}`)}`;
                // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
}

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}



