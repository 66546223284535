import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import Page from "./../../components/Page";
import NormalCard from './../../components/Cards/NormalCard';
import BankAccountData from './../Profile/BankAccount'
import DematAccountData from './../Profile/DematAccount'
import { userService } from "./../../_services/users";
import SnackBar from "./../../components/SnackBar/SnackBar";
import {
  Grid,
  makeStyles,
  FormControlLabel, Checkbox, FormHelperText
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Button from "@material-ui/core/Button";
import PhoneInput from "react-phone-input-2";
import { getLookUpDataService } from "./../../_services/lookUp";
import { useLocation, useNavigate } from "react-router-dom";
import loadingSpinner from "./../../images/loading-spinner.gif";
import ReactSpinner from "./../../components/ReactSpinner/ReactSpinner";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => 
({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  addressBlock: {
    padding: "1rem",
    color: "#314B8F",
    // marginTop: "1rem",
  },
  accountBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  accountBtn: {
    // borderRadius: "0 0 12px 12px",
    marginTop: "1rem",
  },
  accountTitle: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },
  addressBlocktitle: {
    fontSize: "1.2rem",
    fontWeight: "500",
    borderBottom: "1px solid #314B8F",
    padding: "10px",
    marginTop: "1rem",
    color: "#314B8F",
  },
  reactSpinner: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "black70",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CreateUser = (props) => {
  const classes = useStyles();
  const [userID, setUserID] = React.useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
const theme=props.authReducer.theme
  const loginId = props && props.authReducer && props.authReducer.auth.user.id
  const [spinner, setSpinner] = useState(false);
  const partnerCode = props.authReducer?.auth?.user?.related_partner_code
  // const [country, setCountry] = useState([]);
  const [mode, setMode] = useState(null);
  const [rmList, setRmlist] = useState([]);
  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState("");
  const [valueColor, setValueColor] = useState(false);
  const [roles, setRoles] = useState([]);
  const [userBasicData, setUserBasicData] = useState({
    firstName: "",
    lastname: "",
    email: "",
    phone: "",
    pan: "",
    selectRole: '',
    clientRM: '',
    isClient: false,
    user_type: "INDIVIDUAL",
    user_role: ["PARTNER_CLIENT"],
    loginPhone: '',
    login: false
  })
  const phoneRegex = RegExp(/^[0-9]{12}$/);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let location = useLocation();


  const handleError = (value) => {
    if (value.length <= 2) {
      setValueColor(true);
    } else if (value.length <= 10) {
      setValueColor(true);
    } else {
      setValueColor(false);
    }
  };

  const handleClose = (event, reason) => {

    setOpenSnackBar(false);
  };

  const visibleAllScrips = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.is_visible_all_scrips
  const visibleScripIds = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.visible_scrip_ids

  const isRM = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.default_role_code === "PARTNER_RELATIONSHIP_MANAGER" ? true : false
  const RM_code = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.user_unique_code
  const reporting_user = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.representative_code
  const admin_id = props.authReducer && props.authReducer.auth && props.authReducer.auth.user.admin_id

  const crateUser = ((values) => {
    setLoading(true)
    // const userRoles = values.selectRole.map((item) => item.code);
    let userData = {
      user_type: "INDIVIDUAL",
      user_role: isRM ? ["PARTNER_CLIENT"] : [values.selectRole.code],
      required_login: values.login,
      username: values.loginPhone,
      required_price_update: false,
      first_name: values.firstName,
      last_name: values.lastname,
      login_mobile_no: values.loginPhone,
      email: values.email,
      contact_no: values.phone,
      pan: values.pan.toUpperCase(),
      country_code: countryCode,
      partner_code: partnerCode,
      visible_scrip_ids: visibleScripIds,
      is_all_scrip_visible: visibleAllScrips,
      reporting_user: reporting_user,
      admin_id: admin_id,
      rm_id: values.clientRM.id ?values.clientRM.id : RM_code,
      partner_representative: values.clientRM ? values.clientRM.id : RM_code
    };

    if (values.loginPhone && values.loginPhone.charAt(0) !== "+") {
      userData.login_mobile_no = `+${values.loginPhone}`;
    }
    if (mode === "edit") {
      setSpinner(true);
      userService.updateUser(userID, userData).then((res) => {
        if (res) {
          setSpinner(false);
          if (res.status === 200) {
            setLoading(false);
            setSeverity("success");
            setSnackbarTitle("User basic Info Updated");
            setOpenSnackBar(true);
          } else if (res.status === 400) {
            setLoading(false);
            if (res.data === "MOBILE_NUMBER_ALREAY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("Mobile no already exist");
              setOpenSnackBar(true);
            } else if (res.data === "PAN_ALREADY_USED") {
              setSeverity("error");
              setSnackbarTitle("PAN already used");
              setOpenSnackBar(true);
            } else if (res.data === "COMPANEY_GSTIN_ALREADY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("GST already used");
              setOpenSnackBar(true);
            } else if (
              res.data === "COMPANEY_REGISTRATION_NUMBER_ALREADY_EXISTS"
            ) {
              setSeverity("error");
              setSnackbarTitle("Reg.No already used");
              setOpenSnackBar(true);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("OOPS something went wrong!");
                setOpenSnackBar(true);
              }
            }
          }
        } else {
          setLoading(false);
        }
      });
    } else if (mode === "create") {
      userService
        .createUser(userData)
        .then((res) => {
          if (res.status === 201) {
            setLoading(false);
            setUserID(res.data.id);
            setSeverity("success");
            setSnackbarTitle("User Created Successfully");
            setOpenSnackBar(true);
            setTimeout(() => {
              navigate('/app/users')
            }, 2000)
          } else if (res.status === 400) {
            setLoading(false);
            if (res.data === "MOBILE_NUMBER_ALREAY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("Mobile no already exist");
              setOpenSnackBar(true);
            } else if (res.data === "PAN_ALREADY_USED") {
              setSeverity("error");
              setSnackbarTitle("PAN already used");
              setOpenSnackBar(true);
            } else if (res.data === "COMPANEY_GSTIN_ALREADY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("GST already used");
              setOpenSnackBar(true);
            } else if (
              res.data === "COMPANEY_REGISTRATION_NUMBER_ALREADY_EXISTS"
            ) {
              setSeverity("error");
              setSnackbarTitle("Reg.No already used");
              setOpenSnackBar(true);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("OOPS something went wrong!");
                setOpenSnackBar(true);
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  })
  const getUserDetails = (async () => {
    if (query.get("mode") === "edit" && userID != null) {
      setSpinner(true);
      let initValues = { ...userBasicData };
      await userService.getUserDetails(location.state.userID).then((res) => {
        if (res) {
          if (res.status === 200) {
            setSpinner(false);
            initValues.login = res.data[0].required_login;
            initValues.loginPhone =
              res.data[0].login_mobile_no &&
              res.data[0].login_mobile_no.substring(1);
            initValues.firstName = res.data[0].first_name;
            initValues.lastname = res.data[0].last_name;
            initValues.email = res.data[0].email;
            initValues.phone = res.data[0].contact_no;
            initValues.pan = res.data[0].pan;
            initValues.selectRole = res.data[0].user_role[0];
            initValues.isClient = res.data[0].user_role[0].code === "PARTNER_CLIENT" ? true : false
            if (res.data[0].clientRM !== null) {
              initValues.clientRM = {
                id: res.data[0].partner_representative_unique_code,
                name: `${res.data[0].partner_representative_name}`,
              };
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      });

      setUserBasicData(initValues);
    }
  })
  useEffect(() => {
    getUserDetails()
    getAllPartnerRM()
  }, [userID]);


  const getAllPartnerRM = (() => {
    userService.getPartnerRm(loginId).then((res) => {
      if (res) {
        if (res.status === 200) {
          let array = []
          res.data.message.map((item) => {
            array.push({
              id: item.user_unique_code,
              name: item.name,
            })
          })
          setRmlist(array)
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            // setOpenSessionExpiredModal(true);
          }
        }
      }
    })
  })

  useEffect(() => {
    setMode(query.get("mode") || null);
    if (query.get("mode") === "edit") {
      setUserID(location.state.userID || null);
    }
    getLookUpDataService.getRolesByUserRole().then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data) {
            setRoles(res.data);
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    }
    );

  }, []);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  return (
    <React.Fragment>
      <Page title="Unlistedkart | User Create">
      {/* <MuiThemeProvider theme={darkTheme}>   */}

        <Formik
          enableReinitialize={true}
          initialValues={userBasicData}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("First Name is required"),
            email: Yup.string()
              .required("Email is invalid")
              .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "Email is invalid"
              ),
            pan: Yup.string()
              .required("Pan is required")
              .matches(
                /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                "Pan is invalid"
              ),
            loginPhone: Yup.string().when("login", {
              is: true,
              then: Yup.string()
                .nullable()
                .required("Phone is required")
                .matches(phoneRegex, "Enter a valid Mobile Number"),
              otherwise: Yup.string().nullable(),
            }),
            selectRole: !isRM && Yup.object().required("Required"),
            // clientRM: !isRM && Yup.object().when("isClient", {
            //   is: true,
            //   then: Yup.object()
            //     .required("RM is required"),
            //   otherwise: Yup.object().nullable(),
            // }),
            phone: Yup.string()
              .required("Phone is required")
              .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                "Phone number is not valid"
              ),
          })}
          onSubmit={(values) => {
            crateUser(values)
            // navigate('/app/dashboard', { replace: true });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} >
              <NormalCard title="Basic Information">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div style={{ padding: "1rem" }}>
                  {!isRM && <Grid container spacing={5} >
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Autocomplete
                        id="selectrole"
                        options={roles}
                        getOptionLabel={(option) => option.description}
                        value={values.selectRole}
                        onChange={(e, value) => {
                          setFieldValue("selectRole", value);
                          let partnerRM = value.position_index === 7
                          if (partnerRM) {
                            setFieldValue("isClient", true);
                          } else {
                            setFieldValue("isClient", false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.selectRole &&
                              errors.selectRole
                            )}
                            helperText={
                              touched.selectRole &&
                              errors.selectRole
                            }
                            name="selectRole"
                            label="Select User Type (Role) *"
                            fullWidth
                            variant="standard"
                          />
                        )}
                      />
                      {/* <ErrorFocus /> */}
                    </Grid>
                    {values.isClient && <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Autocomplete
                        id="selectrm"
                        options={rmList}
                        getOptionLabel={(option) => option.name || ''}
                        value={values.clientRM}
                        onChange={(e, value) => {
                          setFieldValue("clientRM", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched.clientRM && errors.clientRM
                            )}
                            helperText={
                              touched.clientRM && errors.clientRM
                            }
                            name="clientRM"
                            label="Select Client RM"
                            fullWidth
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>}
                  </Grid>}

                  <Grid container spacing={5} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ paddingTop: "5px" }}
                    >
                      <TextField
                        label="First Name"
                        id="first-name"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        value={capitalizeFirstLetter(values.firstName)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ paddingTop: "5px" }}
                    >
                      <TextField
                        fullWidth
                        label="Last Name"
                        id="last-name"
                        margin="normal"
                        name="lastname"
                        type="lastname"
                        value={capitalizeFirstLetter(values.lastname)}
                        onChange={handleChange}
                        error={Boolean(touched.lastname && errors.lastname)}
                        helperText={touched.lastname && errors.lastname}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ paddingTop: "5px" }}
                    >
                      <TextField
                        label="Email"
                        id="email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        value={values.email}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={5} alignItems="center">


                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ paddingTop: "5px" }}
                    >
                      <TextField
                        label="Phone"
                        id="phone"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        value={values.phone}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ paddingTop: "5px" }}
                    >
                      <TextField
                        label="Pan"
                        id="pan"
                        name="pan"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.pan && errors.pan)}
                        fullWidth
                        helperText={touched.pan && errors.pan}
                        value={values.pan?.toUpperCase()}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="login"
                            checked={values.login}
                            color="primary"
                          />
                        }
                        onChange={handleChange}
                        label="Required login"
                        name="login"
                        id="login-mob"
                        value={values.login}
                      />
                    </Grid>
                    {values.login && (
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <PhoneInput
                          id="mobile"
                          name="loginPhone"
                          onlyCountries={[
                            "in",
                            "us",
                            "au",
                            "sg",
                            "hk",
                            "ae",
                            "gb",
                            "nz",
                            "ph",
                          ]}
                          countryCodeEditable={false}
                          country={"in"}
                          style={
                            valueColor
                              ? {
                                border: "1px solid red",
                                borderRadius: "5px",
                              }
                              : {
                                border: "1px solid #314B8F",
                                borderRadius: "5px",
                              }
                          }
                          placeholder="Enter phone number"
                          value={values.loginPhone}
                          handleBlur={handleBlur}
                          onChange={(e, data) => {
                            handleError(e);
                            setFieldValue("loginPhone", e);
                            setCountryCode(data.countryCode);
                          }}
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {errors.loginPhone &&
                            touched.loginPhone &&
                            errors.loginPhone}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>

                  <div style={{ textAlign: "end" }}>
                    <Button
                      // color="primary"
                      className={classes.accountBtn}
                      size="medium"
                      type="submit"
                      disabled={
                        loading ||
                        (mode === "create" && userID !== null) 
                      }
                      variant="outlined"
                    >
                      {loading === false ? (
                        <> {mode === "edit" ? "Update" : "Create"}</>
                      ) : (
                        <>
                          <img
                            src={loadingSpinner}
                            className={classes.buttonProgress}
                          />{" "}
                          {mode === "edit" ? "Update" : "Create"}{" "}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </NormalCard>

            </form>
          )}

        </Formik>

        {/* <Paper elevation={3} className={classes.addressBlock}> */}

        {/* </Paper>     */}

        <DematAccountData theme={theme} userID={userID} edit={true} />
        <BankAccountData theme={theme} userID={userID} edit={true} />
        <SnackBar
          open={openSnackBar}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />

      {/* </MuiThemeProvider> */}
      </Page>

    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps)(CreateUser);