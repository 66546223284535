import React from 'react'
import { createTheme } from "@material-ui/core/styles";

const StyledTable = (props) => {
  const themeMode = props ==='dark'? true:false
    const getMuiTheme = () =>
    createTheme({
      overrides: {

        MUIDataTable:{
          responsiveBase:{
            scrollbarWidth:'none',
            scrollbarColor: "#888 #f0f0f0",
            height: "83vh",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: "5px",
              // display:'none',
              height:'2px'
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor:themeMode? "#252d3a": '#3e3e3e',
              // display:'none'
            },
          }
        },
        MuiAppBar: {
            colorPrimary: {
                boxShadow: 'none',
                backgroundColor: 'none',
                color: themeMode? '#fff': '#324C90',
                marginTop: '4px',
                marginBottom: '-15px'
            }
        },
        MuiToolbar:{
          root:{
            color:themeMode ? "#fff" : ''
          }
        },
        MUIDataTableHeadCell: {
          root:{
            whiteSpace: 'nowrap',
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : '',
            "&.sortActive":{
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : ''
            },
          },
            // fontFamily:"system-ui !important"
          
        
        
          hintIconAlone: {
            marginLeft: "4px",
            marginTop: "4px",
            color: " gray",
            fontFamily:"system-ui "
          },
        },
        MUIDataTableHead:{
          root:{
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : ''
          },
         
        },
        MUIDataTableHeadCell:{
          fixedHeader:{
            backgroundColor:themeMode ?"#252d3a" : '',
            whiteSpace: "nowrap",
          },
          sortActive:{
            color:themeMode ? "#fff" : ''
          }
        },
        MuiButton:{
          root: {
          fontFamily:"system-ui",
          color:themeMode ? "#fff" : '',
          },
          label:{
            color:themeMode ? "#fff" : ''
          }
      },
      MUIDataTableFilter:{
        root:{
          backgroundColor:themeMode ? "#1c1c1c" : '',
          color:themeMode ? "#fff" : ''
        },
      },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: "nowrap",
            color:themeMode ? "#fff" : '',

          },
        },
        MuiInputBase:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiFormLabel:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSvgIcon:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSelect:{
          root:{
            color:themeMode ? "#fff" : '',
          },
          MuiSvgIcon:{
            color:themeMode ? "#fff" : '',

          },
          MuiSvgIcon:{
            root:{
              color:themeMode ? "#fff" : '',
            }
          }
        },
        MuiTypography:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiTableCell: {
          root: {
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "16px",
            paddingRight: "16px",
            fontFamily:"system-ui ",
            color:themeMode ? "#fff" : ''
            
          },
          head:{
            color:themeMode ? "#fff" : ''

          }
        },
        MuiTabs: {
          indicator: {
            background:themeMode ? "#fff" : "#324C90",
          },
        },
        MuiBox: {
          root: {
            padding: 0,
          },
        },
        MuiPaper: {
          elevation4: {
              boxShadow: "0px 0px 3px lightGrey",
              marginTop: '16px',
              marginBottom: '12px',
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : '',
          }
      },
      MuiMenu:{
        paper:{
          backgroundColor:themeMode ?"#1d1d1d" : '',
          color:themeMode ? "#fff" : '',
        }
      },
        MUIDataTablePagination: {
          tableCellContainer: {
            padding: 0,
          },
          MUIDataTableBodyCell: {
            stackedCommon: {
              width: "100% !important",
            },
          },
        },
      },
    });
  return getMuiTheme
}

export default StyledTable