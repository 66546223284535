import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { makeStyles, useTheme, Typography, Backdrop,CircularProgress, Grid, Box, CardContent, colors, Button, IconButton, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
import Page from '../../components/Page';
import SessionExpiredModal from "../../components/AlertModal/SessionExpiredModal"
import PropTypes from 'prop-types';
import { serviceOrderService } from "./../../_services/serviceOrder";
import ModalCentered from "./../../components/Modals/ModalCentered";
import OrderCreateForm from './OrderCreateForm';
import SnackBar from "./../../components/SnackBar/SnackBar";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useNavigate, NavLink } from 'react-router-dom';
import UploadIcon from './../../images/upload.svg'
import VisibilityIcon from '@material-ui/icons/Visibility';
import UploadPOD from './UploadPOD';
import { orderService } from "./../../_services/orders";
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit';
import Orderstatus from './Orderstatus'
import { Description } from '@material-ui/icons';
import ImageRotate from "../../components/Image/ImageRotate";
import { getLookUpDataService } from "../../_services/lookUp";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
    // eslint-disable-line
    return <NavLink to={props.to} {...props} />; // eslint-disable-line
  });
  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    tableEllips: {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    },
    btnright: {
        textAlign: "end",
    },
    // button: {
    //     marginBottom: "1%",
    //     borderRadius: "14px 14px 0 0",
    //     boxShadow: "none",
    //     textTransform: "capitalize",
    // },
    upWard: {
        fontWeight: "500",
        fontSize: "1rem"
    },
    downWard: {
        fontWeight: "500",
        fontSize: "1rem",
        color: "red"
    },

    profit: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56
    },
    loss: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56
    },
    card: {
        borderBottom: "none",
        padding: 0,
    },
    bottomborder: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #e8e8e8",
    },

    iconbtn: {
        padding: theme.spacing(0.8),
      },
    root: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },
    addButton: {
        textAlign: 'end',
        marginLeft: "10px"
    },
    container: {
        padding: '24px 24px 0'
    },

    button: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },
    italic: {
        fontStyle: "italic",
        fontWeight: '00',
        fontSize: '0.8rem'
    },
    editdeletbtn: {
        color: '#3363cb',
        display: 'flex'
    },
    btnsdltedt: {
        marginRight: theme.spacing(1)
    },
    iconbtn: {
        padding: theme.spacing(1.2)
    },
    companyLogo: {
        "&:hover": {
            transform: 'scale(3.5)',
            filter: 'alpha(opacity=30)'
        },
    },
    removeLink: {
        cursor: 'pointer'
    }
}))




const OrderData = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const [backdrop, setBackDrop] = useState(false)
    const [loading, setLoading] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [orderStatusModal, setOrderStatusModal] = useState(false);
    const [currentStatus, setCurrentStatus] = useState({});
    const [paymentId, setPaymentId] = useState();
    const [clientData, setClientData] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [value, setValue] = useState(0);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [severity, setSeverity] = React.useState("");
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState(false);
    const [show, setShow] = useState(false);
    const role = props?.authReducer?.auth?.user?.default_role_code
    const logo = props?.authReducer?.auth?.user?.partner_logo
   const themeMode = props.authReducer.theme ==='dark' ? true: false
    const getMuiTheme = () =>
    createTheme({
      overrides: {

        MUIDataTable:{
          responsiveBase:{
            scrollbarWidth:'none',
            scrollbarColor: "#888 #f0f0f0",
            height: "83vh",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: "5px",
              // display:'none',
              height:'2px'
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor:themeMode? "#252d3a": '#3e3e3e',
              // display:'none'
            },
          }
        },
        MuiAppBar: {
            colorPrimary: {
                boxShadow: 'none',
                backgroundColor: 'none',
                color: themeMode? '#fff': '#324C90',
                marginTop: '4px',
                marginBottom: '-15px'
            }
        },
        MuiToolbar:{
          root:{
            color:themeMode ? "#fff" : ''
          }
        },
        MUIDataTableHeadCell: {
          root:{
            whiteSpace: 'nowrap',
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : '',
            "&.sortActive":{
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : ''
            },
          },
            // fontFamily:"system-ui !important"
          
        
        
          hintIconAlone: {
            marginLeft: "4px",
            marginTop: "4px",
            color: " gray",
            fontFamily:"system-ui "
          },
        },
        MUIDataTableHead:{
          root:{
            backgroundColor:themeMode ?"#252d3a" : '',
            color:themeMode ? "#fff" : ''
          },
         
        },
        MUIDataTableHeadCell:{
          fixedHeader:{
            backgroundColor:themeMode ?"#252d3a" : '',
            whiteSpace: "nowrap",
          },
          sortActive:{
            color:themeMode ? "#fff" : ''
          }
        },
        MuiButton:{
          root: {
          fontFamily:"system-ui",
          color:themeMode ? "#fff" : '',
          },
          label:{
            color:themeMode ? "#fff" : ''
          }
      },
      MUIDataTableFilter:{
        root:{
          backgroundColor:themeMode ? "#1c1c1c" : '',
          color:themeMode ? "#fff" : ''
        },
      },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: "nowrap",
            color:themeMode ? "#fff" : '',

          },
        },
        MuiInputBase:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiFormLabel:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSvgIcon:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiSelect:{
          root:{
            color:themeMode ? "#fff" : '',
          },
          MuiSvgIcon:{
            color:themeMode ? "#fff" : '',

          },
          MuiSvgIcon:{
            root:{
              color:themeMode ? "#fff" : '',
            }
          }
        },
        MuiTypography:{
          root:{
            color:themeMode ? "#fff" : '',
          }
        },
        MuiTableCell: {
          root: {
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "16px",
            paddingRight: "16px",
            fontFamily:"system-ui ",
            color:themeMode ? "#fff" : ''
            
          },
          head:{
            color:themeMode ? "#fff" : ''

          }
        },
        MuiTabs: {
          indicator: {
            background:themeMode ? "#fff" : "#324C90",
          },
        },
        MuiBox: {
          root: {
            padding: 0,
          },
        },
        MuiPaper: {
          elevation4: {
              boxShadow: "0px 0px 3px lightGrey",
              marginTop: '16px',
              marginBottom: '12px',
              backgroundColor:themeMode ?"#252d3a" : '',
              color:themeMode ? "#fff" : '',
          }
      },
      MuiMenu:{
        paper:{
          backgroundColor:themeMode ?"#1d1d1d" : '',
          color:themeMode ? "#fff" : '',
        }
      },
        MUIDataTablePagination: {
          tableCellContainer: {
            padding: 0,
          },
          MUIDataTableBodyCell: {
            stackedCommon: {
              width: "100% !important",
            },
          },
        },
      },
    });



  const getProofSignedUrl = (url) => {
    setBackDrop(true)
    setShow(true)
    return  getLookUpDataService.getKycUrl(url).then((res)=>{
      setBackDrop(false)
      return res.data.data
    })
  }
    const getAllClientServiceOrders = () => {
        setLoading(true);
        serviceOrderService
            .getAllClientServiceOrders(value ? false : true)
            .then((res) => {
                setLoading(false)
                if (res) {
                    if (res.status === 200) {
                        setClientData(res.data.data)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true);
                        } else {
                        }
                    }
                } else {
                }
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        getAllClientServiceOrders()
    }, [value]);

    const paymentAction = ((res) => {
        if (res === "success") {
            getAllClientServiceOrders()
            setTimeout(() => {
                setOpenModal(false)
            }, 5000)
            setSeverity("success")
            setSnackbarTitle("Order created")
            setOpen(true);
        } else if (res === "request") {
            getAllClientServiceOrders()
            setOpenModal(false)
            setSeverity("success")
            setSnackbarTitle("Order request placed")
            setOpen(true);
        } else {
            setOpenModal(false)
            setSeverity("error")
            setSnackbarTitle(res)
            setOpen(true);
        }
    })

    const updateOrderStatus = ((values) => {
        const status = {
            "status": values.orderStatus.code,
            "service_order_id": orderId
        }
        serviceOrderService
            .statusOfServiceOrder(status)
            .then((res) => {
                if (res.data === 'Status_Updated') {
                    setSeverity("success")
                    setSnackbarTitle("Status updated")
                    setOpen(true);
                    setOrderStatusModal(false)
                    getAllClientServiceOrders()
                } else {
                    setSeverity("error")
                    setSnackbarTitle("Something went wrong")
                    setOpen(true);
                    setOrderStatusModal(false)
                }
            })
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const navigatetoOrders = ((value) => {

        navigate("/app/scrip-order-list", { replace: true, state: value })

    })
    const selfColumns = [
        {
            name: "buy_order_id",
            label: "Order Id",
            options: {
                filter: false,
                customBodyRender: (value,row) => {
                    return (
                        <Typography 
                        component={LinkBtn}
                        noWrap
                        to={`/app/service-order/${row.rowData[1]}`}
                         
                        // onClick={()=>navigate(`/app/service-order/${row.rowData[1]}`)}
                        style={{ color:themeMode ? "#fff" :"#314B8F" , fontWeight:themeMode ?  600: ''}} variant="span">
                            {value} 
                        </Typography>)
                }
            }
        },
        {
            name: "_id",
            label: "Order Id",
            options: {
                filter: false,
                display: false,
            }
        },
        {
            name: "razorpay_payment_id",
            label: "UTR Number",
            options: {
                display: false,
            }
        },
        {
            name: "total_amount",
            label: "Total Amount",
            options: {
                display: false,
            }
        },
        {
            name: "scrip_name",
            label: "Scrip Name",
        },
        {
            name: "paid_through",
            label: "Payment Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const customValue = value === 'unlistedkart' ? 'Unlistedkart Razorpay' : value === 'imps' ? "Bank IMPS" : value
                    return (
                        <Typography style={{ textTransform: 'capitalize'}} variant="span">
                            {customValue}
                        </Typography>)
                }
            }
        },
        {
            name: "order_status",
            label: "Order Status",
            options: {
                // filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const customValue = value === 'Preprocessing' ? 'Pre-processing' : value
                    return (
                        <Typography variant="span">
                            {customValue}
                        </Typography>)
                }
            }
        },
        {
            name: "create_datetime",
            label: "Created Date",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    let vDateValue = moment(value).format("DD-MM-YYYY h:mm A")
                    return vDateValue
                }
            }
        },
        {
            name: "client_payment_proof",
            label: "Payment Proof",
            options: {
                filter: false,
                customBodyRender: (value, row) => {
                    return (
                        value ? <Button
                            componet="a"
                            style={{ borderRadius: '100%', marginLeft: '1rem'}}
                            // href={value}
                            onClick={async()=> {
                                const  url = value.includes('amazonaws.com/') ? value.split("amazonaws.com/")[1] :value
                                const getUrl = await  getProofSignedUrl(url)
                                 setUrl({
                                   title:row.rowData[0],
                                   url: getUrl,
                                   isPdf:value.endsWith(".pdf") ? true: false
                                 });
                               }}
                            target="_blank"
                            fontSize="small"
                            className={classes.iconbtn}
                        >
                            <VisibilityIcon fontSize='small' />
                        </Button> : <IconButton
                         className={classes.iconbtn}
                        fontSize="small"
                            style={{ marginLeft: '28px' }}
                            onClick={() => {
                                setOpenUploadModal(true)
                                setOrderId(row.rowData[0])
                                setPaymentId({
                                    utrNumber:row.rowData[2],
                                    amount:row.rowData[3]
                                })
                            }}>
                            <img width={15} src={UploadIcon} alt="upload" />
                        </IconButton>
                    )
                },
            },
        },
        {
            name: "order_status_code",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, row) => {
                    console.log(value)
                    return (
                        <IconButton
                        className={classes.iconbtn}
                            disabled={value === "COMPLETED" || value === "ONHOLD"}
                            onClick={() => {
                                setOrderStatusModal(true)
                                setOrderId(row.rowData[1])
                                setCurrentStatus({
                                    code: value,
                                    description: row.rowData[6]
                                })
                            }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )
                },
            },
        },

    ]
    const clientColumns = [
        {
            name: "buy_order_id",
            label: "Order Id",
            options: {
                filter: false,
                customBodyRender: (value,row) => {
                    return (
                        <Typography 
                        component={LinkBtn} 
                        variant="span"
                        noWrap
                    style={{cursor:'pointer', color:themeMode ? "#fff" :"#314B8F", fontWeight:themeMode ?  600: ''}}
                        to={`/app/service-order/${row.rowData[1]}`}
                        // onClick={()=>navigate(`/app/service-order/${row.rowData[1]}`)}
                        >
                            {value}
                        </Typography>)
                }
            }
        },
        {
            name: "_id",
            label: "Order Id",
            options: {
                filter: false,
                display: false,
            }
        },
        {
            name: "razorpay_payment_id",
            label: "UTR Number",
            options: {
                display: false,
            }
        },
        {
            name: "total_amount",
            label: "Total Amount",
            options: {
                display: false,
            }
        },
        {
            name: "investor",
            label: "Client Name",
        },
        {
            name: "scrip_name",
            label: "Scrip Name",
        },
        {
            name: "description",
            label: "Client Role",
        },

        {
            name: "ulk_representative_name",
            label: "ULK RM",
            options: {
                filter: false,
            }
        },
        {
            name: "client_rm",
            label: "Client RM",
            options: {
                filter: false,
                // sort:false
            }
        },
        {
            name: "paid_through",
            label: "Payment Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const customValue = value === 'unlistedkart' ? 'Unlistedkart Razorpay' : value === 'imps' ? "Bank IMPS" : value
                    return (
                        <Typography style={{ textTransform: 'capitalize' }} variant="span">
                            {customValue}
                        </Typography>)
                }
            }
        },

        {
            name: "order_status",
            label: "Order Status",
            options: {
                // filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const customValue = value === 'Preprocessing' ? 'Pre-processing' : value
                    return (
                        <Typography variant="span">
                            {customValue}
                        </Typography>)
                }
            }
        },
        {
            name: "create_datetime",
            label: "Created Date",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    let vDateValue = moment(value).format("DD-MM-YYYY h:mm A")
                    return vDateValue
                }
            }
        },
        {
            name: "client_payment_proof",
            label: "Payment Proof",
            options: {
                filter: false,
                customBodyRender: (value, row) => {
                    return (
                        value ? <Button
                        className={classes.iconbtn}
                            componet="a"
                            style={{ borderRadius: '100%', marginLeft: '1rem' }}
                            // href={value}
                            onClick={async()=> {
                             const  url = value.includes('amazonaws.com/') ? value.split("amazonaws.com/")[1] :value
                             const getUrl = await  getProofSignedUrl(url)
                              setUrl({
                                title:row.rowData[0],
                                url: getUrl,
                                isPdf:value.endsWith(".pdf") ? true: false
                              });
                            }}
                            target="_blank"
                        >
                            <VisibilityIcon fontSize='small' />
                        </Button> : <IconButton
                         className={classes.iconbtn}
                            style={{ marginLeft: '28px' }}
                            onClick={() => {
                                setOpenUploadModal(true)
                                setOrderId(row.rowData[0])
                                setPaymentId({
                                    utrNumber:row.rowData[2],
                                    amount:row.rowData[3]
                                })
                            }}>
                            <img width={15} src={UploadIcon} alt="upload" />
                        </IconButton>
                    )
                },
            },
        },
        {
            name: "order_status_code",
            label: "Action",
            options: {
                filter: false,
                customBodyRender: (value, row) => {
                    console.log(value)
                    return (
                        <IconButton
                        className={classes.iconbtn}
                            disabled={value === "COMPLETED" || value === "ONHOLD"}
                            onClick={() => {
                                setOrderStatusModal(true)
                                setOrderId(row.rowData[1])
                                setCurrentStatus({
                                    code: value,
                                    description: row.rowData[10]
                                })
                            }}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    )
                },
            },
        },
    ]

    const filteredArray = selfColumns.filter(item => item.label !== "Action")
    const parse = ((data) => parseFloat(data).toFixed(2))

    const openModalHandler = (type) => {
        setTitleModal("Create order");
        setOpenModal(true);
    };

    const closeModalHandler = () => {
        setOpenModal(false);
        setOpenUploadModal(false)
        setOrderStatusModal(false)
    };
    const uploadPOD = ((values) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('orderid', orderId)
        formData.append('photos', values.podCopy)
        formData.append('paid_amount', values.amount)
        formData.append('utr_no', values.utrNumber)


        orderService.uploadPOD(formData).then((res) => {
            setLoading(false)
            setOpenUploadModal(false)
            if (res.data.success) {
                setSeverity("success")
                setSnackbarTitle("Uploaded successfully")
                setOpen(true);
                getAllClientServiceOrders(value ? false : true)
            } else {
                setSeverity("error")
                setSnackbarTitle("Upload failed")
                setOpen(true);
            }
        })
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setClientData([])
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };


    return (

        <React.Fragment>
            <Page title="Unlistedkart | Service Order List">
                <ThemeProvider theme={getMuiTheme}>
                   {role !== "PARTNER_CLIENT" && <div className={classes.btnright}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                openModalHandler()
                            }}        >
                            Create Order
                        </Button>
                    </div>}
                    <div style={{ position: 'relative' }}>
                        {role !== "PARTNER_CLIENT" && <div className={classes.bottomborder}>
                            <AppBar position="static">
                                <Tabs value={value} onChange={handleChange} >
                                    <Tab label="Self" {...a11yProps(0)} />
                                    <Tab label="Client" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                        </div>}
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0}>
                                <MUIDataTable
                                    data={clientData}
                                    headerSort={false}
                                    columns={ role == "PARTNER_CLIENT" ? filteredArray :  selfColumns}
                                    options={{
                                        rowsPerPage: 10,
                                        rowsPerPageOptions: [5, 10, 25],
                                        download: false,
                                        viewColumns: false,
                                        selectableRows: false,
                                        print: false,
                                        textLabels: {
                                            body: {
                                                noMatch: loading ?
                                                    'Loading...' : 'Sorry, there is no matching data to display'
                                            },
                                        },
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <MUIDataTable
                                    data={clientData}
                                    headerSort={false}
                                    columns={clientColumns}
                                    options={{
                                        rowsPerPage: 10,
                                        rowsPerPageOptions: [5, 10, 25],
                                        selectableRows: false,
                                        download: false,
                                        viewColumns: false,
                                        print: false,
                                        textLabels: {
                                            body: {
                                                noMatch: loading ?
                                                    'Loading...' : 'Sorry, there is no matching data to display'
                                            },
                                        },
                                    }}
                                />
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </ThemeProvider>
            </Page>
            <ModalCentered
                title={titleModal}
                open={openModal}
                fullScreen={true}
                logo={logo}
                toggle={closeModalHandler}
                children={
                    <OrderCreateForm
                        onClose={closeModalHandler}
                        formSubmit={paymentAction}
                    />
                }
            />
            <ModalCentered
                title={"Upload POD Copy"}
                open={openUploadModal}
                toggle={closeModalHandler}
                children={
                    <UploadPOD
                     data={paymentId}
                        formSubmit={uploadPOD}
                    />
                }
            />
            <ModalCentered
                title={"Update Order Status"}
                open={orderStatusModal}
                toggle={closeModalHandler}
                children={
                    <Orderstatus
                        status={currentStatus}
                        formSubmit={updateOrderStatus}
                    />
                }
            />
             <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalCentered
          title={`Payment Proof ${url?.title ?url?.title : ""}`}
          fullScreen={true}
          open={show}
          toggle={() => {
            setShow(false)
            setUrl(false)
          }}
          children={
            url.url ? (
              <div style={{ textAlign: "center", position: "relative" }}>
                <Grid container style={{ display: "flex",justifyContent:"center" }}>
                  <Grid item xs={12} md={10}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {url.isPdf ? (
                        <iframe
                          style={{
                            width: "100%",
                            height: "calc(100vh - 100px)",
                          }}
                          src={url.url}
                        />
                      ) : (
                        <ImageRotate alt="cool image" image={url && url.url} />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (<div>
              Loading...
            </div>)
          }
        />
            <SnackBar
                open={open}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </React.Fragment>

    )

}

const mapStateToProps = state => {
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
};

export default connect(mapStateToProps)(OrderData);


