import React, { useEffect, useState } from "react";
import { Typography, Grid, Chip, Avatar, Box } from "@material-ui/core";
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import Page from "./../../components/Page";
import NormalCard from "./../../components/Cards/NormalCard";
import { securityService } from "./../../_services/security";
import { useParams } from "react-router-dom";
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal";
import ReactHtmlParser from "react-html-parser";
import CopytoClipboard from "./../../components/ButtonComponents/CopytoClipboard";
import moment from 'moment'
import PropTypes from "prop-types";
import TableStyle from './../../components/Tables/Theme'


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const ScripDetails = (props) => {
    const themeMode = props.themeMode ==='dark' ?true:false     
    const useStyles = makeStyles((theme) => ({
        root: {},
    
        hdrlblbtn: {
            display: "flex",
            // justifyContent: "end",
            marginTop: theme.spacing(0),
            alignItems: "center",
            marginBottom: "1%",
        },
        addButton: {
            textAlign: 'end',
            marginLeft: "10px"
        },
        card: {
            borderBottom: "none",
            padding: 0,
        },
        bottomborder: {
            backgroundColor: "#fff",
            borderBottom: "1px solid #e8e8e8",
        },
        editbutton: {
            borderRadius: "14px 14px 0 0",
            boxShadow: "none",
            textTransform: "capitalize",
            marginLeft: '1rem',
        },
        induvidual: {
            color:themeMode ? "#fff" :'',
            marginTop: theme.spacing(2),
        },
        labelname: {
            [theme.breakpoints.up(576)]: {
                width: "40%",
            },
            color:themeMode ? "#fff" :'',
            width: "40%",
            textAlign: "end",
            fontWeight: "500",
        },
        details: {
            // width: "68%",
            color:themeMode ? "#fff" :'',
            marginLeft: theme.spacing(2),
        },
        formgrgroup: {
            display: "flex",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        removeLink: {
            cursor: "pointer",
        },
        table: {
            padding: "1rem",
    
            color: themeMode ? '#fff' : "rgba(0, 0, 0, 0.87)",
            transition: " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            "& table,th,td": {
                borderBottom: "1px solid #ddd",
            },
            "& th": {
                color: "#ffffff",
                padding: "6px",
                fontWeight: 500,
                lineHeight: "1rem",
                whiteSpace: "nowrap",
                backgroundColor: "#6C6B74",
            },
            "& table": {
                width: "100%",
                textAlign: "left",
                borderCollapse: "collapse",
    
                borderSpacing: 0,
            },
            "& th,td": {
                padding: 8,
                whiteSpace: "nowrap",
            },
            // '& tr:nth-child(even)':{
            //     backgroundColor: "#f2f2f2"
            // }
            "& tr:hover": { backgroundColor: "#f5f5f5" },
            "& tr:nth-child(even)": { backgroundColor: "#f2f2f2" },
            "& tr:nth-child(odd)": { backgroundColor: "#F2FAFA" },
        },
    }));
    const classes = useStyles();
    let id = useParams();
    let useParamsId = id.id ? id.id : props.id;
    const [securityDetails, setSecurityDetails] = useState([]);
    const [companyOverview, setCompanyOverview] = useState("");
    const [loading, setLoading] = useState(false);


    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
        false
    );




    const getDetailsByID = () => {
        setSecurityDetails([])
        setLoading(true)
        securityService.getSecurityByID(useParamsId).then((res) => {
        setLoading(false)
            if (res) {
                if (res.status === 200) {
                    if (res.data[0]) {
                        setSecurityDetails(res.data[0]);

                        if (res.data[0].company_overview === null) {
                            setCompanyOverview("");
                        } else {
                            const removedTag = res.data[0].company_overview
                                .replace(
                                    '<figure class="table">',
                                    '<div style="overflow-x:auto;">'
                                )
                                .replace("</figure>", "</div>");
                            setCompanyOverview(removedTag);
                        }
                    }
                } if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenSessionExpiredModal(true)
                }
            }
        });
    };

    useEffect(() => {
        getDetailsByID();
    }, [props.id]);

    const [hover, setHover] = useState(false);
    const [securityDetailsname, setSecurityDetailsname] = useState("");

    let text = "";
    const onHover = () => {
        setHover(true);

    };
    const InvestorDetails = securityDetails.keyinvestordata ? JSON.parse(securityDetails.keyinvestordata) : []
    const FinancialDetails = securityDetails.financialdata ? JSON.parse(securityDetails.financialdata) : []
    const onLeave = () => {
        setHover(false);
    };
    console.log(loading)

    return (
        <Page title="Unlistedkart | Scrip Details">
            <div className={classes.hdrlblbtn}>
                <Typography className={classes.heder} style={props.id &&{marginTop:"-2rem", fontFamily:'system-ui', color:themeMode ? "#fff" : ''}} variant="h4">
                    Scrip Details
                </Typography>
            </div>
         {loading?
         <Box sx={{display:'grid',placeItems:'center', height:'calc(100vh - 200px)'}}>
         <Typography style={{color:themeMode ? "#fff" : ''}}>Loading...</Typography>
      </Box>
         :<div>
                <NormalCard title="Scrip Details" >
                    <div className={classes.induvidual}>
                        <div className={classes.formgrgroup}>
                            <label className={classes.labelname}>Scrip Name</label>
                            <p className={classes.details}>{securityDetails.name}</p>
                        </div>
                        <div className={classes.formgrgroup}>
                            <label className={classes.labelname}>Sector</label>
                            <p className={classes.details}> {securityDetails.sector}</p>
                        </div>
                    </div>
                </NormalCard>
                <NormalCard title="Company Info." themeMode={props.themeMode}>
                    <div className={classes.induvidual}>
                        <div className={classes.formgrgroup}>
                            <label className={classes.labelname}>Company Name</label>
                            <p className={classes.details}>
                                {securityDetails.parent_company_name}
                            </p>
                        </div>
                        <div className={classes.formgrgroup} onMouseEnter={() => {
                            onHover()
                            setSecurityDetailsname(securityDetails.isin)
                        }}
                            onMouseLeave={onLeave}>
                            <label className={classes.labelname} >ISIN</label>
                            <p className={classes.details}>{securityDetails.isin}</p>
                            {/* {(securityDetailsname === securityDetails.isin && securityDetails.isin !== null) ? <CopytoClipboard
                                userBasicInfo={(text = securityDetails.isin)}
                                state={hover}
                            /> : null} */}
                        </div>
                        {securityDetails?.related_security_tag && (
                            <div className={classes.formgrgroup}>
                                <label className={classes.labelname}>Tags</label>
                                <p className={classes.details}>
                                    {securityDetails?.related_security_tag.map((value) => {
                                        return (
                                            <Chip
                                                style={{ marginRight: "4px", color :  themeMode && '#fff', border :  themeMode && '1px solid #fff'}}
                                                size="small"
                                                avatar={<Avatar alt={value.tag_name} src={value.tag_icon_url} />}
                                                className={classes.chipMargin}
                                                label={value.tag_name}
                                                variant="outlined"
                                            />
                                        )
                                    })}
                                </p>
                            </div>
                        )}
                        <div className={classes.formgrgroup}>
                            <label className={classes.labelname}>Market Capital</label>
                            <p className={classes.details}>
                                {securityDetails.related_market_capital_id === 0 ? "Micro" : securityDetails.related_market_capital_id === 1 ? "Small" : securityDetails.related_market_capital_id === 2 ? "Middle" : securityDetails.related_market_capital_id === 3 ? "Large" : ""}
                            </p>
                        </div>
                        {securityDetails?.status && (
                            <div className={classes.formgrgroup}>
                                <label className={classes.labelname}>Status</label>
                                <p className={classes.details}>
                                    {securityDetails?.status === 1 ? "Available" : securityDetails?.status === 2 ? "Out of Stock" : securityDetails?.status === 3 ? "Coming soon" : null}
                                </p>
                            </div>
                        )}
                        {(securityDetails?.available_cdsl || securityDetails?.available_nsdl) && (
                            <div className={classes.formgrgroup}>
                                <label className={classes.labelname}>Available on</label>
                                <p className={classes.details}>
                                    {securityDetails?.available_cdsl && "CDSL"}  {securityDetails?.available_nsdl && "NSDL"}
                                </p>
                            </div>
                        )}

                        {securityDetails.logo_url && (
                            <div className={classes.formgrgroup}>
                                <label className={classes.labelname}>Logo</label>
                                <div className={classes.details}>
                                    <img
                                        src={securityDetails.logo_url}
                                        width="80px"
                                        alt="Company Logo"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </NormalCard>
                <NormalCard title="Other Details" themeMode={props.themeMode}>
                    <div
                        className={classes.formgrgroup}
                        onMouseEnter={() => {
                            onHover()
                            setSecurityDetailsname(securityDetails.office_no)
                        }}
                        onMouseLeave={onLeave}
                    >
                        <label className={classes.labelname}> &nbsp;&nbsp;Office No</label>
                        <p className={classes.details}>{securityDetails.office_no}</p>
                        {/* {(securityDetailsname === securityDetails.office_no && securityDetails.office_no !== null) ? <CopytoClipboard
                            userBasicInfo={(text = securityDetails.office_no)}
                            state={hover}
                        /> : null} */}
                    </div>
                    <div className={classes.formgrgroup} onMouseEnter={() => {
                        onHover()
                        setSecurityDetailsname(securityDetails.register_no)
                    }}
                        onMouseLeave={onLeave}>
                        <label className={classes.labelname}>Registration No</label>
                        <p className={classes.details}>{securityDetails.register_no} </p>
                        {/* {(securityDetailsname === securityDetails.register_no && securityDetails.register_no !== null) ? <CopytoClipboard
                            userBasicInfo={(text = securityDetails.register_no)}
                            state={hover}
                        /> : null} */}
                    </div>
                    <div className={classes.formgrgroup} onMouseEnter={() => {
                        onHover()
                        setSecurityDetailsname(securityDetails.email)
                    }}
                        onMouseLeave={onLeave}>
                        <label className={classes.labelname}>Email ID</label>
                        <p className={classes.details}> {securityDetails.email}</p>
                        {/* {(securityDetailsname === securityDetails.email && securityDetails.email !== null) ? <CopytoClipboard
                            userBasicInfo={(text = securityDetails.email)}
                            state={hover}
                        /> : null} */}
                    </div>

                    <div className={classes.formgrgroup} onMouseEnter={() => {
                        onHover()
                        setSecurityDetailsname(securityDetails.gstin)
                    }}
                        onMouseLeave={onLeave}>
                        <label className={classes.labelname}>GSTIN</label>
                        <p className={classes.details}> {securityDetails.gstin} </p>
                        {/* {(securityDetailsname === securityDetails.gstin && securityDetails.gstin !== "") ? <CopytoClipboard
                            userBasicInfo={(text = securityDetails.gstin)}
                            state={hover}
                        /> : null} */}
                    </div>
                    <div className={classes.formgrgroup} onMouseEnter={() => {
                        onHover()
                        setSecurityDetailsname(securityDetails.pan)
                    }}
                        onMouseLeave={onLeave}>
                        <label className={classes.labelname}>PAN</label>
                        <p className={classes.details}>{securityDetails.pan}</p>
                        {/* {(securityDetailsname === securityDetails.pan && securityDetails.pan !== "") ? <CopytoClipboard
                            userBasicInfo={(text = securityDetails.pan)}
                            state={hover}
                        /> : null} */}
                    </div>
                </NormalCard>
                {/* </TabPanel>
                        <TabPanel value={activeTab} index={1}> */}
                <NormalCard title="Investor Details" themeMode={props.themeMode}>
                    <div className={classes.induvidual}>
                        {InvestorDetails && InvestorDetails.length >= 1 ? (
                            <div>
                                <div className={classes.formgrgroup}>
                                    <label className={classes.labelname}>Total  Investment</label>
                                    <p className={classes.details}>{securityDetails.totalinvestment}</p>
                                </div>
                                <div className={classes.formgrgroup}>
                                    <label className={classes.labelname}>Investor Name </label>
                                    <p className={classes.details} style={{ fontWeight: "500" }}>Percentage</p>
                                </div>
                                <hr></hr>
                                {InvestorDetails.map((item, index) => {
                                    return (
                                        <div className={classes.formgrgroup}>
                                            <label className={classes.labelname} style={{ fontWeight: 400 }}>{item.investorName}</label>
                                             <p className={classes.details}>{item.investorPercentage}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                            : (
                                <div style={{ textAlign: "center" }}>
                                    Sorry, there are no matching data to display
                                </div>
                            )}

                    </div>
                </NormalCard>
                <NormalCard title="Financial Details" themeMode={props.themeMode}>
                    <div className={classes.induvidual}>
                        {FinancialDetails && FinancialDetails.length >= 1 ?
                            <div>
                                {FinancialDetails.map((item, index) => {
                                    return (
                                        <div className={classes.formgrgroup}>
                                            <label className={classes.labelname}>{item.financialName}</label>
                                            <p className={classes.details}>{item.financialValue} {item.financialName === "P/E Ratio" ? "%" : "Crores"}</p>
                                        </div>
                                    )
                                })}
                                <div className={classes.formgrgroup}>
                                    <label className={classes.labelname}>Face Value</label>
                                    <p className={classes.details}>{securityDetails.face_value}</p>
                                </div>
                                <div className={classes.formgrgroup}>
                                    <label className={classes.labelname}>Report Date</label>
                                    <p className={classes.details}>{moment(securityDetails.financiallastupdatedate).format("DD-MM-YYYY")}</p>
                                </div>
                            </div>
                            : (
                                <div style={{ textAlign: "center" }}>
                                    Sorry, there are no matching data to display
                                </div>
                            )}
                    </div>
                </NormalCard>
                <NormalCard title="Company Overview" themeMode={props.themeMode}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.gridPadding}
                        >
                            <div className={classes.table}>
                                {ReactHtmlParser(companyOverview)}
                            </div>
                        </Grid>
                    </Grid>
                </NormalCard>
            </div>}
            <SessionExpiredModal open={openSessionExpiredModal} />
        </Page>
    );
};

export default ScripDetails;
