import axios from 'axios'
import { helpers } from './../_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const getLookUpDataService = {
    getRolesByUserRole,
    getObjectUrl,
    getKycUrl
};


const userId =helpers.setUserId()



function getRolesByUserRole(){
const userId =helpers.setUserId()
helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/role_lookups_hierarchy/?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getObjectUrl(id){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getPresignedUrlByFileID/?id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getKycUrl(url) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getPresignedUrlKYCdocs`, JSON.stringify({ url: url}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}



