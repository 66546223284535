import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      width={50}
      height={50}
      src={props.url}
      {...props}
    />
  );
};

export default Logo;
