/*eslint-disable*/
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  Paper,Popper,MenuItem,MenuList,Grow,ClickAwayListener
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Logo from "../../components/Logo";
import { connect } from "react-redux";
import { logoutSuccess, setTheme } from "../../store/bugs";
import { useNavigate } from "react-router-dom";
// import IdelTimerContainer from "src/components/IdelTimer/IdelTimeModal";
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
import Cookies from 'universal-cookie';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FlareIcon from '@material-ui/icons/Flare';
import Brightness4Icon from '@material-ui/icons/Brightness4';


const TopBar = (props) => {
  const cookies = new Cookies();
  const [notifications] = useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const anchorRef = React.useRef(null);
  const theme = props.authReducer.theme ==='dark' ? true :false
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: theme ? "#222831" : null ,
    },
    avatar: {
      width: 60,
      height: 60,
    },
    logoName: {
      display: "flex",
      alignItem: "center",
      color: "#fff",
    },
    logoHome:{
      color: "#fff",
    },
    leftAlign:{
      placement:"right"
    }
  }));
  function removeCookie(name, path) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path ;
  }
  const classes = useStyles();
  const logout = () => {
    props.logoutSuccess();
    removeCookie("token", "/");
    removeCookie('userName', "/")
    removeCookie('password', "/")
    removeCookie('userId', "/")
    navigate("/login", { replace: true });
  };
const Name = props.authReducer && props.authReducer.auth && props.authReducer.auth.user?.display_name
const logo = props.authReducer && props.authReducer.auth && props.authReducer.auth.user?.partner_logo

const handleToggle = () => {
  setOpen((prevOpen) => !prevOpen);
};

const handleClose = () => {
  setOpen(false);
};

function handleListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setOpen(false);
  }
}

  return (
    <AppBar className={clsx(classes.root, props.className)} elevation={0} >
      <Toolbar>
        {/* <RouterLink to="/"> */}
          {/* <IdelTimerContainer/> */}
          <div className={classes.logoName}>
         { logo &&<Logo  src={logo}/>}
            <Typography variant="h4" style={{ margin: "auto", marginLeft: 10 }}>
              {Name} 
            </Typography>
          </div>
        {/* </RouterLink> */}
        <Box style={{flexGrow:'1'}} />
        <IconButton color="inherit" onClick={handleToggle}
         ref={anchorRef}
         aria-controls={open ? 'menu-list-grow' : undefined}
         aria-haspopup="true">
          <Tooltip title="Theme" arrow className={classes.leftAlign}>   
            <ColorLensIcon  />
          </Tooltip>
        </IconButton>
        <IconButton color="inherit" onClick={logout}>
          <Tooltip title="Logout" arrow className={classes.leftAlign}>   
            <ExitToAppIcon  />
          </Tooltip>
        </IconButton>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={props.onMobileNavOpen}>
            <Tooltip title="Menu" arrow className={classes.leftAlign}>   
              <MenuIcon />
            </Tooltip>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem 
                    onClick={()=>{
                      handleClose()
                      localStorage.setItem('theme','dark')
                      props.setTheme({'theme':'dark'})
                    }}> <Brightness4Icon
                    
                     style={{margin:'0 4px'}}  />Dark Theme</MenuItem>
                    <MenuItem 
                    onClick={()=>{
                      handleClose()
                      localStorage.setItem('theme','light')
                      props.setTheme({theme:'light'})
                    }} > <FlareIcon 
                     style={{margin:'0 4px'}} />Light Theme</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};


const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};


const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  setTheme: (theme) => dispatch(setTheme(theme)),
});

export default connect(mapStateToProps, dispatchToProps, )(TopBar);
