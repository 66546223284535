import React, { useEffect } from "react";
import { makeStyles, } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


import Autocomplete from "@material-ui/lab/Autocomplete";

import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
    Grid,
    Container,
    TextField,
} from "@material-ui/core";
import loadingSpinner from './../../images/loading-spinner.gif'

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles((theme) => ({

    container: {
        justifyContent: "center",
        justifyItems: "center",

        marginTop: "5%",
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    popDownBtn: {
        // borderRadius: "0 0 12px 12px",
        padding: "8px 1rem",
        float: "right",
        marginTop: theme.spacing(3),
        marginBottom: "2%",
    },

    buttonProgress: {
        marginRight: '4px'
    },
    inputMargin: {
        '& .MuiFormHelperText-contained': {
            margin: '4px 0 0'
        }
    }
}));


function UpdateOrderStatus(props) {
    const classes = useStyles();


    const [loading, setLoading] = React.useState(false);
    const timer = React.useRef();

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        'application/pdf',
        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

   const  userData =[
    {
        "id": 2,
        "code": "INACTIVE",
        "description": "Inactive",
        "position_index": 2
    },
    {
        "id": 6,
        "code": "CANCELLED",
        "description": "Cancelled",
        "position_index": 6
    },
    {
        "id": 7,
        "code": "ONHOLD",
        "description": "Onhold",
        "position_index": 7
    },
    {
        "id": 8,
        "code": "PREPROCESSING",
        "description": "Preprocessing",
        "position_index": 8
    }
]

    return (
        <Container>
            <Formik
                initialValues={{
                    orderStatus: props.status,
                }}
                validationSchema={Yup.object().shape({
                    orderStatus: Yup.object().required('Status is required'),

                })}
                onSubmit={(values) => {
                    setLoading(true)
                    props.formSubmit(values)

                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,

                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        
                        <Container className={classes.container}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                    id="selectrole-role"
                                    options={userData}

                                    getOptionLabel={(option) => option.description || " "}
                                    value={values.orderStatus}
                                    onChange={(e, value) => {
                                        setFieldValue("orderStatus", value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(
                                                touched.orderStatus && errors.orderStatus
                                            )}
                                            helperText={
                                                touched.orderStatus && errors.orderStatus
                                            }
                                            name="orderStatus"
                                            label="Select order status*"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <ErrorFocus />


                            <Button
                                color="primary"
                                disabled={isSubmitting || values.orderStatus?.code === props.status?.code}
                                className={classes.popDownBtn}
                                size="medium"
                                type="submit"
                                variant="outlined"

                            >
                                {loading === false ? "Update" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Update </>}
                            </Button>
                        </Container>
                    </form>
                )}
            </Formik>
        </Container>
    );
}

export default UpdateOrderStatus;
