import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Typography,
  Box
  } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({

  zeroPadding:{
    padding:0
  },
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  cardHeaderMargin: {
    padding: '1rem 1rem',
    // backgroundColor: theme.palette.primary.main,
    // padding: 20,
    // left:theme.spacing(1),
    // textAlign:'center',
    color: theme.palette.primary.main,
    // border: "1px solid #324c90",
    // width: "max-content",
    fontSize:"16px",
    fontWeight:500,
    borderRadius: "0 0  5px 0",
  },
  fileInput:{
    display: "none"
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",

    // marginTop: "5%",
  },
  label:{
    marginBottom:theme.spacing(1),
    color:theme.palette.primary.main
  },
  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  popDownBtn: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "5%%",
  },

  resendBtnMargin:{
    marginTop: theme.spacing(3)
  },
  
  inputMargin:{
    marginBottom:theme.spacing(2)
  }
}));

  const styles = (theme) => ({
    root: {
      borderRadius:"0 10px 10px 10px",
      backgroundColor: theme.palette.background.paper,

    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.7),
      color: theme.palette.primary.main,
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      root: {
        padding: theme.spacing(2),
      },
    },
  }))(MuiDialogContent);

 

  function AddDematAccountPage(props) {
    const classes = useStyles();
  

    
    const handleClose = () => {
      props.toggle()
    };

    return (
      <Dialog  fullScreen={props.fullScreen} fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}  className= {classes.cardBorderRadius}>
       <DialogTitle id="customized-dialog-title" onClose={handleClose} className= {classes.zeroPadding}>
       { props.fullScreen && props.title ? 
        <Typography variant="h6" className={classes.cardHeaderMargin}>
        {props.title}
      </Typography> : props.fullScreen?
       <Box>
       
        <Typography variant='h2' style={{textAlign:'center', padding:'1rem', fontFamily:'system-ui', display:"flex", justifyContent:'center',alignItems:'center'}}>  <img src={props.logo} width={100} style={{margin:'0 1rem'}} alt=""/> Checkout Order</Typography>
       </Box>  :   <Typography variant="h6" className={classes.cardHeaderMargin}>
              {props.title}
            </Typography>}
            {}
          </DialogTitle>
              <DialogContent dividers>
                  {props.children}          
              </DialogContent>        
        </Dialog>
    );
  }


export default  AddDematAccountPage;