import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  makeStyles,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";
import Page from "./../../components/Page";
import { securityService } from "./../../_services/security";
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal";
import { Search } from "@material-ui/icons";
import ModalCentered from "./../../components/Modals/ModalCentered";
import OrderCreateForm from "./../Orders/OrderCreateForm";
import SnackBar from "./../../components/SnackBar/SnackBar";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import ScripDetails from "./ScripDetails";
import SortIcon from "@material-ui/icons/Sort";
import CloseIcon from "@material-ui/icons/Close";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Zoom } from "@material-ui/core";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});


const SecurityData = (props) => {
const themeMode = props.authReducer.theme ==="dark" ?true: false
  const useStyles = makeStyles((theme) => ({
    tableEllips: {
      display: "flex",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "11rem",
      [theme.breakpoints.down("sm")]: {
        width: "8rem",
      },
      [theme.breakpoints.up("sm")]: {
        width: "11rem",
      },
    },
    ellipse: {
      WebkitLineClamp: 1,
      overflow: "hidden",
      maxWidth: "100%",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
    card: {
      borderBottom: "none",
      padding: 0,
    },
    bottomborder: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #e8e8e8",
    },
    searchInput: {
      width: "100%",
      maxWidth: 400,
      borderBottomColor:themeMode ? '#fff' : '',
      margin: theme.spacing(1),
      color:themeMode ? "#fff" : '',
      "& .MuiInputBase-input": {
      color:themeMode ? "#fff" : '',
      }
    },
    root: {
      margin: theme.spacing(2),
      "& .MuiTableHead-root": {
        background: "blue",
      },
    },
    addButton: {
      textAlign: "end",
      marginLeft: "10px",
    },
    container: {
      // minHeight: '100%',
      padding: "24px",
    },
  
    button: {
      marginBottom: "1%",
      borderRadius: "14px 14px 0 0",
      boxShadow: "none",
      textTransform: "capitalize",
    },
    card: {
      padding: "10px",
      transition: "transform .2s",
      // backgroundClip:'content-box',
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    },
    editdeletbtn: {
      color: "#3363cb",
      display: "flex",
    },
    btnsdltedt: {
      marginRight: theme.spacing(1),
    },
    iconbtn: {
      padding: theme.spacing(0.8),
    },
    companyLogo: {
      "&:hover": {
        transform: "scale(3.5)",
        filter: "alpha(opacity=30)",
      },
    },
    removeLink: {
      cursor: "pointer",
    },
    gridView: {
      scrollbarWidth: "thin",
      scrollbarColor:themeMode? "#fff": "#888",
      width: "5px",
      height: "83vh",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor:themeMode? "#fff": "rgba(0,0,0,.1)",
      },
    },
  }));
  
  const classes = useStyles();
  const gridRef = useRef(null);
  const [securityData, setSecurityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] =
    React.useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredScrip, setFilteredScrip] = useState([]);
  const [data, setData] = useState();
  const [id, setId] = useState(null);

  const [open, setOpen] = useState(false);

  const options = [
    {
      id: 1,
      name: "Price (low to high)",
    },
    {
      id: 2,
      name: "Price (high to low)",
    },
    {
      id: 3,
      name: "Scrip Name (A-Z)",
    },
    {
      id: 4,
      name: "Scrip Name (Z-A)",
    },
  ];

  const role = props?.authReducer?.auth?.user?.default_role_code;
  const logo = props?.authReducer?.auth?.user?.partner_logo;

  const paymentAction = (res) => {
    if (res === "success") {
      // getAllClientServiceOrders()
      setTimeout(() => {
        setOpenModal(false);
      }, 5000);
      setSeverity("success");
      setSnackbarTitle("Order created");
      setOpen(true);
    } else if (res === "request") {
      setOpenModal(false);
      setSeverity("success");
      setSnackbarTitle("Order request placed");
      setOpen(true);
    } else {
      setOpenModal(false);
      setSeverity("error");
      setSnackbarTitle("Order creation failed");
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getSecurityData = () => {
    setLoading(true);
    console.log('hi')
    securityService
      .getSecurity()
      .then((res) => {
        setLoading(false);
        if (res) {
          if (res.status === 200) {
            setSecurityData(res.data.data);
            setFilteredScrip(res.data.data);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true)
            }
            else if (res.status === 403) {
              setLoading(false);
              setSeverity("error");
              setSnackbarTitle("Something went wrong");
              setOpen(true);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const handleScrollToTop = () => {
    if (gridRef.current) {
      gridRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    getSecurityData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const openModalHandler = (type) => {
    setTitleModal("Create order");
    setOpenModal(true);
  };
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    const filteredArray = securityData.filter(
      (obj) =>
        obj.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        obj.sector.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredScrip(filteredArray);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };
 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFilter = (e) => {
    handleCloseMenu();
    const filteredArray =
      e === 1
        ? securityData.sort((a, b) => a.price - b.price)
        : e === 2
        ? securityData.sort((a, b) => b.price - a.price)
        : e === 3
        ? securityData.sort((a, b) => a.name.localeCompare(b.name))
        : securityData.sort((a, b) => b.name.localeCompare(a.name));
    setFilteredScrip(filteredArray);
  };

  return (
    <React.Fragment>
      <Page title="Unlistedkart | Security List">
        <TextField
          // style={{position:'fixed' ,top:'50px',width:'100%',marginBottom:'30px', background:'#fff'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search style={{color:themeMode ? "#fff" : ''}}/>
              </InputAdornment>
            ),
          }}
          className={classes.searchInput}
          label="Search scrips"
          // variant="outlined"
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
        <Tooltip title="Sort">
          <IconButton
            aria-label="more"
            id="long-button"
            style={{ marginTop: "15px" }}
            aria-controls={menu ? "long-menu" : undefined}
            aria-expanded={menu ? "true" : undefined}
            aria-haspopup="true"
            
            onClick={handleClick}
          >
            <SortIcon  style={{color:themeMode ? "#fff" : ''}}/>
          </IconButton>
        </Tooltip>
        <Menu
          style={{ margin: "2.5rem 14px" }}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={menu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              backgroundColor:themeMode ? "#0b1a26" : '',
              color:themeMode ? "#fff" : '',
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem  key={option.id} onClick={() => handleFilter(option.id)}>
              {option.name}
            </MenuItem>
          ))}
        </Menu>
        {id && (
          <IconButton onClick={() => setId(null)} style={{ float: "right" }}>
            <CloseIcon style={{color:themeMode ? "#fff" : '',}} />
          </IconButton>
        )}
        <Grid container spacing={2} >
          <Grid item xs={id ? 6 : 12}  className={classes.gridView}>
            <Grid container spacing={2}>
              {filteredScrip.length >= 1 ? (
                filteredScrip.map((item) => {
                  return (
                    <Grid
                      item              
                      xs={4}
                      md={id ? 4 : 2}
                      lg={id ? 4 : 2}
                      key={item.id}
                    >
                      <Card className={classes.card} style={{backgroundColor:themeMode ? '#23313a': '#fff', color:themeMode ? '#fff':  '#2e2e2e'}}>
                        {/* <Grid container>
                                        <Grid item xs={4} style={{ padding: '8px', alignSelf: 'center', backgroundColor: 'rgb(216 218 229)' }}> */}
                        <CardMedia
                          component="img"
                          alt="green iguana"
                          style={{
                            height: "50px",
                            width: "60px",
                            margin: "0 16px",
                          }}
                          image={
                            item.logo_url
                              ? item.logo_url
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_fbveBmBPqAkVOIjPxtxPb7B1nzxgN1eH_e5vAXg&s"
                          }
                        />
                        {/* </Grid>
                                        <Grid item xs={8}> */}
                        <CardContent>
                          <Typography
                            className={classes.ellipse}
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h6"
                            className={classes.ellipse}
                            style={{ fontSize: "12px", color: "grey" }}
                            component="div"
                          >
                            {item.sector}
                          </Typography>
                          <Typography gutterBottom variant="h6" component="div">
                            ₹ {item.price}
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{ justifyContent: "space-between" }}
                        >
                          <Button
                            onClick={
                              () => {
                                setId(item.id);
                                handleScrollToTop();
                              }
                              // navigate(`/app/scrip-details/${item.id}`)
                            }
                            style={{color : themeMode ? "#fff" : ''}}
                            size="small"
                          >
                            View More
                          </Button>
                          {role !== "PARTNER_CLIENT" && (
                            <Button
                              onClick={() => {
                                openModalHandler();
                                setData({
                                  id: item.id,
                                  name: item.name,
                                  price: item.price,
                                  logo:item.logo_url
                                });
                              }}
                              variant="outlined"
                              style={{color:themeMode? '#fff': '#2e2e2e', border:themeMode? '1px solid #fff': '1px solid #2e2e2e'}}
                              size="small"
                            >
                              Buy
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Typography style={{ margin: "1rem",color:themeMode ? "#fff" : '', }}>
                  {loading ? "Loading..." : "Oops, no scrips found"}
                </Typography>
              )}
            </Grid>
          </Grid>
          { id &&   <Zoom in={id} out={id} timeout={500} /* Add other props as needed */>
            <Grid
              item
              style={{display:id?'block' : 'none'}}
              xs={id ? 6 : 0}
              className={classes.gridView}
              ref={gridRef}
            >
            <ScripDetails id={id} themeMode= {props.authReducer.theme} setId={setId}></ScripDetails>
            </Grid>
          </Zoom>}
        </Grid>
      </Page>
      <ModalCentered
        title={titleModal}
        open={openModal}
        fullScreen={true}
        logo={logo}
        toggle={closeModalHandler}
        children={
          <OrderCreateForm
            data={data}
            themeMode={themeMode}
            onClose={closeModalHandler}
            formSubmit={paymentAction}
          />
        }
      />
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(SecurityData);
