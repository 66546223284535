import axios from 'axios'
import { helpers } from './../_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const userService = {
    getUsers, 
    createUser,
    createBankAccount,
    createDemat,
    getUserDetails,
    getUserBankAccounts,
    getUserDemat,
    updateUser,
    getPartnerRm,
    checkIsDematPasswordProtected
};

async function getUsers(limit=10,offset=0,searchText='',filterText='',code) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        const data = await axios.get(`/partner/rm_user_info_detail?callingUserId=${userId}&limit=${limit}&offset=${offset}&searchText=${searchText}&filter=${filterText}&code=${code}`, requestOptions);
        return data;
    } catch (error) {
        return error.response;
    }
}

function getUserDetails(id) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/users/basic_info/${id}/?callingUserId=${userId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function createUser(payloads) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        const data = await axios.post(`/partner/user/?callingUserId=${userId}`, JSON.stringify(payloads), requestOptions);
        return data;
    } catch (error) {
        return error.response;
    }
}

async function createBankAccount(userID, formData){
    const userId =helpers.setUserId() 
    helpers.setHeader();
    try {
        const res = await axios.post(`/partner/user/bank/?callingUserId=${userId}&user_id=${userID}`, formData);
        return res;
    } catch (error) {
        return error.response;
    }
}

async function  createDemat(userID, formData){
    const userId =helpers.setUserId() 
    helpers.setHeader();
    try {
        const res = await axios.post(`/partner/demat/?callingUserId=${userId}&user_id=${userID}`, formData);
        return res;
    } catch (error) {
        return error.response;
    }
}

function getUserBankAccounts(id) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/user/bank/?callingUserId=${userId}&user_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getUserDemat(id) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/partner/demat/?callingUserId=${userId}&user_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateUser(id, payloads) {
    const userId =helpers.setUserId() 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/partner/users/basic_info_update/${id}/?callingUserId=${userId}`, JSON.stringify(payloads), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getPartnerRm(userID){
    const userId =helpers.setUserId() 
    helpers.setHeader();
    try {
        const res = await axios.get(`/partner/getAllPartnerRMbyAdminID/${userID}/?callingUserId=${userId}`);
        return res;
    } catch (error) {
        return error.response;
    }
}

async function checkIsDematPasswordProtected(formData){
 
    helpers.setHeader();
   
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/checkPdfProtected`,formData, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }
