/*eslint-disable*/
import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles  
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';



const NavItem = ({
  className,
  href,
  icon,
  themeMode,
  title,
  ...rest
}) => {
  const useStyles = makeStyles((theme) => ({
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.fontWeightRegular,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      padding: '10px 8px',
      textTransform: 'none',
      width: '100%',
      borderRadius:0,
    },
    icon: {
      color: themeMode ?'#fff' :'',
      marginRight: theme.spacing(1)
    },
    title: {
      color: themeMode ?'#fff' :'',
      marginRight: 'auto'
    },
    active: {
      color: theme.palette.primary.main,
      borderRight:`3px solid ${theme.palette.primary.main}`,
      '& $title': {
        fontWeight: theme.fontWeightMedium
      },
      '& $icon': {
        color: theme.palette.primary.main
      }
    }
  }));
  
  const classes = useStyles(); 

  let location = useLocation();


  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        // activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
       
       <Icon  className={classes.icon}>{icon}</Icon>
        <span className={classes.title}>
          {title}
        </span>
      </Button>
    </ListItem> 
     );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
