import 'react-perfect-scrollbar/dist/css/styles.css';
import React,{useEffect,useState} from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { darkTheme, lightTheme } from './theme';
import store from './store/configureStore';
import customTheme from './theme';
import GlobalStyles from './components/GlobalStyles'
import routes from './routes';
import { connect } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios';
const App = (props) => {
  const routing = useRoutes(routes);
  const [isLoading,setIsloading] = useState(true)
  useEffect(()=>{
   console.log("log 1");
    fetch('/config.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    ).then((response)=>{
    return response.json();
    }).then((data)=>{
      axios.defaults.baseURL = data.ApiEndPoint
       setIsloading(false)
    })
    
  
 

  },[])

 const theme =props.theme === 'dark' ? darkTheme : lightTheme;
console.log(theme)
  return (
    !isLoading ? ( <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>):(<></>)
    
  );
};


const mapStateToProps = state => {    
  return ({
      force: state, // force state from reducer
      theme: state.authReducer.theme,
  })
};

const ConnectedApp = connect(mapStateToProps)(App); // Connect the component

let persistor = persistStore(store);
const RootApp = () => (

  <Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
    <ConnectedApp />
    </PersistGate>
  </Provider>
);

export default RootApp;
