import React, { useEffect, useState } from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import NormalCard from './../../components/Cards/NormalCard'
import { makeStyles } from '@material-ui/styles';
import { Typography, IconButton, Button,Grid,CircularProgress } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description';
import Chip from '@material-ui/core/Chip';
import { userService } from './../../_services/users';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from "mui-datatables";
import ImageIcon from '@material-ui/icons/Image';
import ModalCentered from "./../../components/Modals/ModalCentered";
import SnackBar from "./../../components/SnackBar/SnackBar";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal"
import BankCreateForm from './BankCreateForm'
import StyledTable from '../../components/Tables/Theme';
import { getLookUpDataService } from '../../_services/lookUp';
import ImageRotate from '../../components/Image/ImageRotate';

const useStyles = makeStyles((theme) => ({
    addButton: {
        fontSize: '13px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
            padding: '4px'
        }
    },
    iconpdf: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-1)
    },
}))
const theme = createTheme({
    overrides: {
        MuiPaper: {
            elevation4: {
                boxShadow: "0 0 0 0 "
            }
        },

        editdeletbtn: {
            display: 'flex',
            color: '#3363cb'
        },
        MuiTableCell: {
            root: {
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '16px',
                paddingRight: '16px'

            }
        },
        MUIDataTablePagination: {
            tableCellContainer: {
                border: 'none',
                paddingBottom: '30px',

            }
        },
        MuiCardContent: {
            root: {
                paddingBottom: '30px'
            }
        },
        MUIDataTable: {
            responsiveBase: {
                paddingBottom: '20px'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                '&:nth-child(7)': {
                    fontSize: 0
                },
                '&:nth-child(8)': {
                    fontSize: 0
                },
            }
        },
    },
})

const BankAccountData = (props) => {

    const classes = useStyles();
    const [bankAccountData, setBankAccountData] = useState([])
    const [loading, setLoading] = useState(false);
    const [show, setShow] = React.useState(false);
    const [url, setUrl] = React.useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID, setAccountID] = useState()
    const [spinner, setSpinner] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

    const [hover, setHover] = useState(false);
    const [rowindex , setrowIndex] = React.useState('');
    const [columnindex , setcolumnIndex] = React.useState('');
  const onHover = () => {
      setHover(true);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    const handleFileUrl = (value) => {
        setShow(true)
        getLookUpDataService.getObjectUrl(value[6]).then((res) => {
          if (res.data.success) {
            setUrl({
              name: value[1],
              bankName: value[2],
              accountNumber: value[3],
              ifscCode: value[4],
              url: res.data.data,
              isPdf:value[7] ==="application/pdf" ? true: false
            });
          }
        });
      };

   

    const BankAccountDetailsHeading = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "beneficiary_name",
            label: "Beneficiary Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "bank_name",
            label: "Bank",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "account_no",
            label: "AC No",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                      <div onMouseEnter={()=>{
                          onHover()
                          setrowIndex(tableMeta.rowIndex)
                          setcolumnIndex(tableMeta.columnIndex)
                      }} onMouseLeave={onLeave}   style={{ display: 'flex' }} >
                       
                            <Typography
                                variant='body2'
                                style={{ textTransform: 'capitalize' }}
                               noWrap
                                 >
                                {value}
                            </Typography>
                     
                      </div>
                    );
                  },
            }
        },
        {
            name: "ifsc_code",
            label: "IFSC Code",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                      <div onMouseEnter={()=>{
                          onHover()
                          setrowIndex(tableMeta.rowIndex)
                          setcolumnIndex(tableMeta.columnIndex)
                      }} onMouseLeave={onLeave}   style={{ display: 'flex' }} >
                       
                            <Typography
                                variant='body2'
                                style={{ textTransform: 'capitalize' }}
                               noWrap
                                 >
                                {value}
                            </Typography> 
                      </div>
                    );
                  },
            }
        },
        {
            name: "account_type",
            label: "AC Type",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "fileid",
            label: "POD",
            options: {
                hint:"Prof of Document",
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Typography
                            style={{ maxWidth: 300, color:props.theme ==='dark' ? '#fff' : '' ,cursor:'pointer'}}
                            component={"a"}
                            noWrap={false}
                            variant='body2'
                            onClick={()=> handleFileUrl(tableMeta.rowData)}
                            // href={`${value}`}
                            color="primary"
                            Download
                            target="_blank"
                        >
                            {( tableMeta.rowData[7] === "image/gif" || tableMeta.rowData[7] === "image/png" || tableMeta.rowData[7] === "image/jpeg" || tableMeta.rowData[7] === "image/jpg") && (<ImageIcon className={classes.iconpdf} size='small' />)}
                            {tableMeta.rowData[7] === "application/pdf" && (<PictureAsPdfIcon className={classes.iconpdf} size='small' />)}
                            {/* {tableMeta.rowData[7] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon className={classes.iconpdf} size='small' />)} */}
                            {'View'}
                        </Typography>
                    )
                } 
            }
        },
        {
            name: "mime_type",
            label: "mime_type",
            options: {
                filter: false,
                sort: false,
                display: false,

            }
        },
        {
            name: "is_primary",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return value === true ? (
                        <Chip size="small" label={"Primary"} color="primary" />)
                        : props.edit ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* <Chip
                                    size="small"
                                    label="Make Primary"
                                    onClick={async () => {
                                        await setLoading(true)
                                        makePrimaryDemat(tableMeta.rowData[0])
                                    }}
                                    style={{ marginRight: 10 }}

                                /> */}
                            </div>) : null
                }
            }
        },
        // {
        //     name: "is_primary",
        //     label: "Action",
        //     options: {
        //         sort: false,
        //         customBodyRender: (value, tableMeta) => {
        //             return props.edit ? (
        //                 <React.Fragment>
        //                     {/* {type === "button" && (<span className={classes.editdeletbtn} style={{ display: 'flex' }}> */}
        //                     <IconButton
        //                         edge="end"
        //                         disabled={value}
        //                         aria-label="delete"
        //                         onClick={() => handleConfirm(tableMeta.rowData[0])}
        //                     >
        //                         <DeleteIcon />
        //                     </IconButton>
        //                     {/* </span>)} */}
        //                 </React.Fragment>
        //             ) : null
        //         }
        //     }
        // }
    ]

    const handleShowClose=()=>{
        setShow(false)
        setUrl(false)
    }
    

    const deleteDocument = () => {
        setLoadSpinner(true)
        setDeleteConfirm(false)
        setLoadSpinner(false)
        userService.deleteBankAccount(props.userID, accountID)
            .then(res => {
                if (res) {
                   

                    if (res.status === 200) {
                        getBankAccounts();
                        setSeverity("success");
                        setSnackbarTitle("Bank Account Deleted Successfully");
                        setOpenSnackBar(true)
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    const handleConfirm = (accID) => {
        setDeleteConfirm(true);
        setAccountID(accID)
    }

    const makePrimaryDemat = (id) => {
        userService.makePrimaryBank(id, props.userID,)
            .then(res => {
                if (res) {
                   

                    if (res.status === 200) {
                        getBankAccounts()
                        setSeverity("success");
                        setSnackbarTitle("Updated");
                        setOpenSnackBar(true);
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    useEffect(() => {
        if (props.userID !== null) {
            getBankAccounts();
        }

    }, [props.userID])

    const getBankAccounts = () => {
        userService.getUserBankAccounts(props.userID)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setBankAccountData(res.data)
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    }


    const openModalHandler = (type, add) => {
        setTitleModal("Add Bank Account");
        setSeverity("success");
        setSnackbarTitle("Bank Account Added Successfully");
        setOpenModal(true);
    }

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    const submitHandleBankAccount = (values) => {
        setSpinner(true)
        // handleOnSubmit(values)
        // setFMIdProofDoc(target.files[0])
        const formData = new FormData();
        formData.append('beneficiary_name', values.beneficiryName)
        formData.append('bank_name', values.bankName)
        formData.append('account_no', values.accountNumber)
        formData.append('ifsc_code', values.ifscCode)
        formData.append('bank_account_type', values.accountType)
        formData.append('is_primary', values.primaryAccount)
        formData.append('POD_COPY', values.podCopy) //file

        userService.createBankAccount(props.userID, formData).then((res) => {
            if (res.status === 201) {
                getBankAccounts();
                setOpenModal(false);
                setOpenSnackBar(true);
                setSpinner(false)
            }else{
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenModal(false);
                    setOpenSessionExpiredModal(true)
                }
            }
        })
    };

    return (
        <React.Fragment>

            <NormalCard title="Bank Account Details"
                className={classes.cardroot}
                btn={props.edit ?
                    <Button
                        color="primary"
                        onClick={() => openModalHandler("demat", "create")}
                        size="medium"
                        type="submit"
                        variant="outlined"
                        className={classes.addButton}
                        name="addDemat"
                        value="addDemat"
                        disabled={props.userID !== null ? false : true}
                    >
                        Add Account
                  </Button> : null
                }

            >
                <div style={{margin:'4px'}}>

                <MuiThemeProvider theme={StyledTable(props.theme)}>

                    <MUIDataTable
                        data={bankAccountData}
                        columns={BankAccountDetailsHeading}
                        options={{
                            download: false,
                            viewColumns: false,
                            filter: false,
                            pagination: false,
                            search: false,
                            print: false,
                            rowsPerPage: 5,
                            rowsPerPageOptions: [5, 10, 25],
                            selectableRows: false,
                            // display: props.display,
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        'Loading...' : 'Sorry, there are no matching data to display'
                                },
                            },
                        }}
                    />

                </MuiThemeProvider>
                </div>
            </NormalCard>

            <ModalCentered
                title={titleModal}
                open={openModal}
                toggle={closeModalHandler}
                children={
                    <BankCreateForm spinner={spinner} formSubmit={submitHandleBankAccount} />
                }
            />

            {/* <AlertModal
                title="Are you sure you want to delete this account?"
                open={deleteConfirm}
                toggle={() => setDeleteConfirm(false)}
                onConfirm={deleteDocument}
                openLoading={loadSpinner}
            >
            </AlertModal> */}

            <SnackBar
                open={openSnackBar}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
   <ModalCentered
        title={"Bank Account"}
        fullScreen={true}
        open={show}
        toggle={handleShowClose}
        children={
            <div style={{textAlign:'center', position:'relative'}}>
           {url?  <Grid container style={{display:'flex'}}>
                <Grid item xs={12} md={2} style={{textAlign:'start'}}>
                    <Typography><b>Name:</b> {url.name}</Typography>
                    <Typography><b>Bank Name:</b> {url.bankName}</Typography>
                    <Typography><b>Bank Account Number:</b> {url.accountNumber}</Typography>
                    <Typography><b>Bank IFSC Code:</b> {url.ifscCode}</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
         <div style={{ 
                alignItems:'center', display:'flex', justifyContent:'center'}}>
                     { url.isPdf? <iframe style={{width:'100%', height:'calc(100vh - 100px)'}} src={url.url}/>:
        <ImageRotate
      alt="cool image"
      image={url &&url.url}/>}
         </div>
               </Grid>
            </Grid> : (
                <Typography> <CircularProgress size={20} /> Loading...</Typography>
              )}
          
            </div>}
      />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </React.Fragment>
    )

}

export default BankAccountData