import React, { useEffect, useState } from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import NormalCard from './../../components/Cards/NormalCard'
import { makeStyles } from '@material-ui/styles';
import { Typography, IconButton, Button,Grid,CircularProgress } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Chip from '@material-ui/core/Chip';
import { userService } from './../../_services/users';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from "mui-datatables";
import ModalCentered from "./../../components/Modals/ModalCentered";
import DematCreateForm from "./DematCreateForm";
import SnackBar from "./../../components/SnackBar/SnackBar";
import ImageIcon from '@material-ui/icons/Image';
// import DescriptionIcon from '@material-ui/icons/Description';
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal"
import StyledTable from './../../components/Tables/Theme'
import { getLookUpDataService } from "../../_services/lookUp";
import ImageRotate from '../../components/Image/ImageRotate';
const useStyles = makeStyles((theme) => ({
    iconpdf: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-1)
    },
    addButton:{
        fontSize:'13px',
        [theme.breakpoints.down('xs')]: {
            fontSize:'11px',
            padding: '4px'
        }
    }
}))
const theme = createTheme({
    overrides: {
        MuiPaper: {
            elevation4: {
                boxShadow: "0 0 0 0 "
            }
        },

        editdeletbtn: {
            display: 'flex',
            color: '#3363cb'
        },
        MuiTableCell: {
            root: {
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '16px',
                paddingRight: '16px'

            }
        },
        MUIDataTablePagination: {
            tableCellContainer: {
                border: 'none',
                paddingBottom: '30px',

            }
        },
        MuiCardContent: {
            root: {
                paddingBottom: '30px'
            }
        },
        MUIDataTable: {
            responsiveBase: {
                paddingBottom: '20px'
            }
        },
       
      
        MUIDataTableHeadCell: {
            root: {
                '&:nth-child(5)': {
                    fontSize: 0
                },
                '&:nth-child(6)': {
                    fontSize: 0
                },
            },
            hintIconAlone:{
                marginLeft:"4px",
                marginTop:'2px',
                color:' gray'
            }
        },   
        
    },
})

const DematAccountData = (props) =>{
    const theme=props.theme
    console.log(theme)
    const classes = useStyles();
    const [dematData, setDematData] = useState([])
    const [loading , setLoading] = useState(false);
    const [spinner , setSpinner] = useState(false);
    // const [disable, setDisable] =  useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [url, setUrl] = useState(false);
    const [show, setShow] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID,setAccountID] = useState()
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [hover, setHover] = useState(false);
    const [rowindex , setrowIndex] = React.useState('');
    const [columnindex , setcolumnIndex] = React.useState('');
  const onHover = () => {
      setHover(true);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    const handleFileUrl = (value) => {
        setShow(true)
        getLookUpDataService.getObjectUrl(value[4]).then((res) => {
          if (res.data.success) {
            setUrl({
              dpWith: value[1],
              dpId: value[2],
              clientId: value[3],
              url: res.data.data,
              isPdf:value[5] ==="application/pdf" ? true: false
            });
          }
        });
      };

    
    const dematAccountDetailsHeading = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "dp_with",
            label: "DP With",
            options: {
                // hint:'Depository Service company that your account is with (Eg. Zerodha, Angel Broking)',
                filter: false,
                sort: false,
                  }
        },
        {
            name: "dp_id",
            label: "DP ID",
            options: {
                // hint:'Depository Participant Identification number assigned to the Depository company',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                      <div onMouseEnter={()=>{
                          onHover()
                          setrowIndex(tableMeta.rowIndex)
                          setcolumnIndex(tableMeta.columnIndex)
                      }} onMouseLeave={onLeave}   style={{ display: 'flex' }} >
                       
                            <Typography
                                variant='body2'
                                style={{ textTransform: 'capitalize' }}
                               noWrap
                                 >
                                {value}
                            </Typography>
                     
                      </div>
                    );
                  },
            }
        },
        {
            name: "client_id",
            label: "Client ID",
            options: {
                // hint:'Client ID - Client ID under the Depository account',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                      <div onMouseEnter={()=>{
                          onHover()
                          setrowIndex(tableMeta.rowIndex)
                          setcolumnIndex(tableMeta.columnIndex)
                      }} onMouseLeave={onLeave}   style={{ display: 'flex' }} >
                       
                            <Typography
                                variant='body2'
                                style={{ textTransform: 'capitalize' }}
                               noWrap
                                 >
                                {value}
                            </Typography>
                     
                      </div>
                    );
                  },
            }
        },
        {
            name: "fileid",
            label: "CML Copy",
            options: {
                // hint:'Client Master List that has information of the Demat account with the Broker',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (                      
                        <Typography
                            // style={{  }}
                            variant='body2'
                            component={"a"}
                            style={{color:props.theme ==='dark' ? '#fff' : '',maxWidth: 300,cursor:"pointer"}}
                            noWrap={false}
                            onClick={()=>handleFileUrl(tableMeta.rowData)}
                            // href={`${value}`}
                            color="primary"
                            Download
                            target="_blank"
                        >
                      
                            {(tableMeta.rowData[5] === "image/jpg" || tableMeta.rowData[5] === "image/png" || tableMeta.rowData[5] === "image/gif" ||  tableMeta.rowData[5] === "image/jpeg") && (<ImageIcon className={classes.iconpdf} size='small' />)}
                            {tableMeta.rowData[5] === "application/pdf" && (<PictureAsPdfIcon className={classes.iconpdf} size='small' />)}
                            {/* {tableMeta.rowData[5] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon className={classes.iconpdf} size='small' />)} */}
                            {'View'}
                        </Typography>
                    )
                }
            }
        },
        // mime_type
        {
            name: "mime_type",
            label: "mime_type",
            options: {
                filter: false,
                sort: false,
                display: false,
            
            }
        },
        {
            name: "is_primary",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return value === true ? (                       
                        <Chip size="small" label={"Primary"} color="primary"/>)
                        : props.edit ? (
                        <div style={{display:"flex", alignItems:"center"}}>
                            {/* <Chip 
                                size="small" 
                                label="Make Primary"
                                onClick={async ()=>{
                                    await setLoading(true)
                                    makePrimaryDemat(tableMeta.rowData[0])
                                }}
                                style={{marginRight:10}}
                                
                            /> */}
                        </div>) : null
                }
            }
        },
        // {
        //     name: "is_primary",
        //     label: "Action",
        //     options: {
        //         sort: false,
        //         customBodyRender: (value, tableMeta) => {
        //             return props.edit ? (
        //                 <span className={classes.editdeletbtn} >
        //                     <IconButton disabled={value} className={classes.iconbtn}>
        //                         <DeleteIcon onClick={() => handleConfirm(tableMeta.rowData[0])} />
        //                     </IconButton>

        //                 </span>
        //             ) : null
        //         }
        //     }
        // }
    ]

    const makePrimaryDemat = (id)=>{
        userService.makePrimaryDemat(props.userID, id)
        .then(res=>{
          if(res){
            
            
            if(res.status===200){
              getDematAccounts();
              setSeverity("success");
              setSnackbarTitle("Updated");
              setOpenSnackBar(true);
            }else{
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenSessionExpiredModal(true)
                }
            }
          }
        })
    }

    useEffect(()=>{
        if(props.userID!==null){
            getDematAccounts();
        }
    },[props.userID])

    const getDematAccounts = () => {
        userService.getUserDemat(props.userID)
        .then(res => {
            if (res) {
                if (res.status === 200) {
                    setDematData(res.data)
                }else{
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })
    }

    const handleConfirm = (accId) => {
        setDeleteConfirm(true);
        setAccountID(accId)        
    }

    const handleDeleteTableRow = () => {
        setLoadSpinner(true)
        setDeleteConfirm(false);
        setLoadSpinner(false)

        userService.deleteUserDemat(accountID).then(res => {
          if (res) {
           

            if (res.status === 200) {
              getDematAccounts()
              setSeverity("success");
              setSnackbarTitle("Demat Account Deleted Successfully");
              setOpenSnackBar(true);  
            }else{
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenSessionExpiredModal(true)
                    setLoadSpinner(false);
                }
            }
          }
        })
    }

    const openModalHandler = (type, add) => {
        setTitleModal("Add Demat Account");
        setSeverity("success");
        setSnackbarTitle("Demat Account Added Successfully");
        setOpenModal(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    }


    const closeModalHandler = () => {
        setOpenModal(false);
    };

    const submitHandle =async (values) => {   
        const formData1 = new FormData();
        setSpinner(true);

        formData1.append("Doc", values.cmlCopy);
        setSpinner(true)
        let password =
      values.cmlCopy.type === "application/pdf"
        ? await userService.checkIsDematPasswordProtected(formData1)
        : false;

    if (password?.data?.passwordProtected) {
      setSpinner(false);
      setSeverity("Error");
      setOpenSnackBar(true);
      setSnackbarTitle("Password protected document is not allowed");
    } else{
        const formData = new FormData();
        formData.append('dp_with', values.dpWith)
        formData.append('dp_id', values.dpId)
        formData.append('client_id', values.clientId)
        formData.append('is_primary', values.primaryAccount)
        formData.append('cml_file', values.cmlCopy)

        userService.createDemat(props.userID, formData).then((res) => {
            if (res.status === 201) {
              getDematAccounts()
              setOpenModal(false);
              setOpenSnackBar(true);
              setSpinner(false)
            }
        })
    }
    };

    return(
        <React.Fragment>
            <NormalCard title="Demat Account Details" 
            className={classes.cardroot}
            btn={props.edit ?
                <Button
                  color="primary"
                  onClick={() => openModalHandler("demat", "create")}
                  size="medium"
                  type="submit"
                  variant="outlined"
                  name="addDemat"
                  className={classes.addButton}
                  value="addDemat"
                  disabled={props.userID !== null ? false : true}
                >
                  Add Account
                  </Button> : null
              }
            >
                
                <div style={{margin:'4px'}}>

                <MuiThemeProvider  theme= {StyledTable(theme)}>
                
                    <MUIDataTable
                        data={dematData}
                        columns={dematAccountDetailsHeading}
                        options={{
                            download: false,
                            viewColumns: false,
                            filter: false,
                            pagination: false,
                            search: false,
                            print: false,
                            rowsPerPage: 5,
                            rowsPerPageOptions: [5, 10, 25],
                            selectableRows: false,
                            // display: props.display,
                            textLabels: {
                                body: {
                                noMatch: loading ?
                                'Loading...': 'Sorry, there are no matching data to display'
                                },
                            },
                        }}
                    />
                </MuiThemeProvider>
                </div>
            </NormalCard>

            <ModalCentered
                title={titleModal}
                open={openModal}
                toggle={closeModalHandler}
                children={
                    <DematCreateForm spinner={spinner} formSubmit={submitHandle} />
                }
            />
              <ModalCentered
        title={"Demat Account"}
        fullScreen={true}
        open={show}
        toggle={()=>setShow(false)}
        children={
            <div style={{ textAlign: "center", position: "relative" }}>
              {url ? (
                <Grid container style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} style={{ textAlign: "start" }}>
                    <Typography>
                      <b>DP With:</b> {url.dpWith}
                    </Typography>
                    <Typography>
                      <b>DP Id:</b> {url.dpId}
                    </Typography>
                    <Typography>
                      <b>Client Id:</b> {url.clientId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {url.isPdf ? (
                        <iframe
                          style={{
                            width: "100%",
                            height: "calc(100vh - 100px)",
                          }}
                          src={url.url}
                        />
                      ) : (
                        <ImageRotate
                          alt="cool image"
                          image={url.url && url.url}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Typography> <CircularProgress size={20} /> Loading...</Typography>
              )}
            </div>
          
        }
      />
            <SnackBar
                open={openSnackBar}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />

            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </React.Fragment>
    )

}

export default DematAccountData