/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import NavItem from "./NavItem";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "../../../store/bugs";
import Cookies from "universal-cookie";
import SessionExpiredModal from "../../../components/AlertModal/SessionExpiredModal";

const cookies = new Cookies();
const user = {
  avatar: "/static/images/avatars/icon-user-default.png",
  jobTitle: "Admin",
  name: "Jyotheesh",
};

const items = [
  {
    href: "/app/users",
    icon: "people",
    title: "Users",
    message: "Master List of Users and Their Role Control",
  },
  {
    href: "/app/orders",
    icon: "confirmation_number_outlined",
    title: "Orders",
    message: "List of Trade",
  },
  {
    href: "/app/scrips",
    icon: "monetization_on_outlined",
    title: "Scrips",
    message: "Master List of Scrips, Control of Inventory & Price",
  },
];

const clientItems = [
  {
    href: "/app/orders",
    icon: "confirmation_number_outlined",
    title: "Orders",
    message: "List of Trade",
  },
  {
    href: "/app/scrips",
    icon: "monetization_on_outlined",
    title: "Scrips",
    message: "Master List of Scrips, Control of Inventory & Price",
  },
];



const NavBar = ({
  onMobileClose,
  openMobile,
  authReducer,
}) => {
  const themeMode = authReducer.theme === "dark" ? true: false
  const useStyles = makeStyles(() => ({
    mobileDrawer: {
      backgroundColor:themeMode ? '#222b38' : '',
      width: 256,
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      // borderLeft:"1px solid #fff",
      backgroundColor:themeMode ? '#222b38' : '',
      height: "calc(100% - 64px)",
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
      background:"#5e5e5e",
      fontSize: "36px",
    },
    name:{
      fontWeight:500,
      // color:"#fff"
    },
    userBg: {
      backgroundImage: `url('/static/images/user-bg-2.png')`,
      backgroundPosition: `left bottom`,
      backgroundRepeat: "no-repeat",
      alignItems:"center",
      display:"flex",
      flexDirection:"column",
      padding:"8px"
    },
  }));
  const classes = useStyles();
  const location = useLocation();

  const role =   authReducer.auth
      ? authReducer.auth.user !== null &&
          authReducer.auth.user.user_role
      : null

const admin =role && role.some((item)=>{
 if(item.code === "PARTNER_ADMIN" || item.code === "PARTNER_RELATIONSHIP_MANAGER"  ){
  return true
 } else{
  return false
 }
})

  const [userMenu, setUserMenu] = useState(null);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  useEffect(() => {
    if (admin) {
      setUserMenu(items);
    } 
    else {
      setUserMenu(clientItems);
    } 
  }, [role]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [show, setShow] = useState(false);

  const openShow = () => {
    if (authReducer.auth.user.user_role.length > 1) {
      setShow(true);
    }
  };




  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        className={classes.userBg}
      >
        <Avatar
          className={classes.avatar}
          // component={RouterLink}
          alt={authReducer.auth.user && authReducer.auth.user.display_name}
          src={user.avatar}
          // to="/app/profile"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {authReducer.auth
            ? authReducer.auth.user !== null &&
              authReducer.auth.user.display_name
            : null}
        </Typography>
        {/* <Typography color="textSecondary" variant="body2">
          <Button onClick={openShow} color="primary">
            {roleDescription}
          </Button>
        </Typography> */}
      </Box>
      <Divider />
      <Box p={0}>
        <List>
          {userMenu &&
            userMenu.map((item, i) => (
              <Tooltip key={i} title={item.message} arrow placement="right">
                <div>
                  <NavItem
                  themeMode={themeMode}
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                </div>
              </Tooltip>
            ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(NavBar);
