/*eslint-disable*/
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import Logo from './../../components/Logo';
import { connect } from 'react-redux';
const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  },
  logoName:{
    display:"flex",
    alignItem:"center",
    color:"#fff"
  }
}));

const TopBar = ({ authReducer,className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
        <div className={classes.logoName}>            
          <Logo src='/static/theme-logo.svg' /> 
          <Typography variant="h4" style={{margin:"auto",marginLeft:10}}>UnlistedKart</Typography>
          </div>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

// const mapStateToProps = (state) => {
//   return {
//     force: state, // force state from reducer
//     authReducer: state.authReducer,
//   };
// };

// export default connect(mapStateToProps,null)(TopBar);
