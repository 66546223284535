import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Typography,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import Page from "./../../components/Page";
import { useNavigate, NavLink } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { userService } from "../../../src/_services/users";
import SessionExpiredModal from "../../../src/components/AlertModal/SessionExpiredModal";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import TableStyle from './../../components/Tables/Theme'
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const useStyles = makeStyles((theme) => ({
  tableEllips: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "7rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "7rem",
    },
  },
  container: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  root: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },
  btnright: {
    textAlign: "end",
  },
  button: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },
  editdeletbtn: {
    color: "#3363cb",
    display: "flex",
  },
  btnsdltedt: {
    marginRight: theme.spacing(1),
  },
  chipMargin: {
    marginRight: "5px",
    marginBottom: "3px",
    fontSize: "12px !important",
    padding: 0,
    height: "20px",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },

  iconbtn: {
    padding: theme.spacing(1.2),
  },
}));

const UsersData = (props) => {
  const code = props && props.authReducer.auth.user?.related_partner_code;
  const themeMode = props.authReducer.theme === 'dark' ? true : false;

  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [perPageRow, setrowsPerPage] = useState(10);
  const [isLoading, setsisLoading] = useState(false);
  const [searchAbleText, setSearchText] = useState("");
  const [sortOrder, setsortOrder] = useState({});
  const [timer, setTimer] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setcount] = useState(0);

  const goToCreateUserPage = () => {
    navigate("/app/user-create?mode=create", { replace: true });
  };
 

  // useEffect(() => {
  //   getUsers();
  //  }, []);

  //  const getUsers = () => {
  //    setLoading(true);
  //    userService
  //      .getUsers()
  //      .then((res) => {
  //        if (res) {
  //          if (res.status === 200) {
  //            setData(res.data);
  //            setLoading(false);
  //          } else {
  //            if (res.status === 401 && res.data.name === "TokenExpiredError") {
  //              setLoading(false);
  //              setOpenSessionExpiredModal(true);
  //            }
  //          }
  //        }
  //      })
  //      .catch((error) => {
  //        console.log(error);
  //        setLoading(false);
  //      });
  //  };
  useEffect(() => {
    getData("", 0);
  }, []);

  const getData = async (url, page) => {
    setsisLoading(true);
    const res = await xhrRequest(url, page);
    if (res.data === "Provide User Name and Password") {
    } else {
      setData(res.data);
      setcount(res.total);
      setsisLoading(false);
    }
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        hide: true,
        display: false,
      },
    },

    {
      name: "name",
      label: "User Name",
      options: {
        // hint: "User Name",
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const name =
            value && value.length > 15 ? value.substr(0, 15) + "..." : value;
          return (
            <div className={classes.tableEllips}>
              <Typography
                variant="span"
                component={LinkBtn}
                noWrap
                to={`/app/user-details/${tableMeta.rowData[0]}`}
                style={{cursor:'pointer', color:themeMode ? "#fff" :"#314B8F" , textTransform: "capitalize", maxWidth: 300 ,fontWeight:themeMode ?  600: ''}}
              >
                {name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "user_role",
      label: "User Role",
    },
    {
      name: "ulk_representative_name",
      label: "ULK RM",
    },
    {
      name: "client_rm",
      label: "Client RM",
    },
    {
      name: "email",
      label: "Email Id",
    },
    {
      name: "contact_no",
      label: "Contact Number",
    },
    {
      name: "pan",
      label: "Pan Number",
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,

        customBodyRender: (value, tableMeta) => {
          return (
            <IconButton
              onClick={() =>
                navigate("/app/user-create?mode=edit", {
                  replace: true,
                  state: { userID: tableMeta.rowData[0] },
                })
              }
              className={classes.iconbtn}
            >
           
              <EditIcon style={{ color:themeMode ? "#fff" : ''}} fontSize="small" />
            </IconButton>
          );
        },
      },
    },
  ];
  const xhrRequest = async (
    url,
    page,
    searchText = "",
    filterText = "",
    rowsPerPage = 10
  ) => {
    // mock page data
    let filterValue = "";
    if (filterText != "") {
      filterValue = filterText;
    } else {
      filterValue = "All";
    }
    let searchingValue = "";
    if (searchText != "") {
      searchingValue = searchText;
    } else {
      searchingValue = searchAbleText;
    }
    let fullData = await userService.getUsers(
      rowsPerPage,
      page,
      searchingValue == null ? "" : searchingValue,
      filterValue,
      code
    );
      setsisLoading(false)
    
    if (fullData.data.length > 0) {
      const total = parseInt(fullData.data[0].total_count);
      let data = fullData.status === 403 ? [] : fullData.data;
      setData(data);
      return {
        data,
        total,
        page,
      };
    } else if(fullData.status === 401 && fullData.data.name === "TokenExpiredError"){
     setOpenSessionExpiredModal(true)
     setsisLoading(false);
    } else{
      setsisLoading(false);
      return {
        data: [],
        total: 0,
        page: 0,
      };
    }
  };

  const search = (page, sortOrder, searchText) => {
    setSearchText(searchText);
    setsisLoading(true);
    xhrRequest(``, page, searchText).then((res) => {
      setData(res.data);
      setsisLoading(false);
      setsortOrder(sortOrder);
      setPage(page);
      setcount(res.total);
    });
  };

  const filterChange = (page, sortOrder, searchText, rowsPerPages = 10) => {
    setsisLoading(true);
    xhrRequest(``, page, searchText, "", rowsPerPages).then((res) => {
      setData(res.data);
      setsisLoading(false);
      setsortOrder(sortOrder);
      setPage(page);
      setcount(res.total);
    });
  };

  return (
    <Page title="Unlistedkart | Users List">
      <ThemeProvider theme={TableStyle(props.authReducer.theme)}>
        <div className={classes.btnright}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={goToCreateUserPage}
          >
            Create User
          </Button>
        </div>
        <MUIDataTable
          title={
            <>
              <Typography variant="h6" style={{fontFamily:"system-ui"}}>
                Users
                {isLoading && (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 , color:themeMode && "#fff"}}
                  />
                )}
              </Typography>
            </>
          }
          data={data}
          columns={columns}
          options={{
            rowsPerPageOptions: [5, 10, 25],
            selectableRows: false,
            download: false,
            viewColumns: false,
            print: false,
            serverSide: true,
            count: count,
            sortOrder: sortOrder,
            rowsPerPage: perPageRow,
            textLabels: {
              body: {
                noMatch: isLoading
                  ? "Loading..."
                  : "Sorry, there is no matching data to display",
              },
            },
            onTableChange: (action, tableState) => {
              switch (action) {
                case "changePage":
                  // setrowsPerPage(tableState.rowsPerPage)
                  // changePage(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                  setrowsPerPage(tableState.rowsPerPage);
                  filterChange(
                    tableState.page,
                    tableState.sortOrder,
                    tableState.searchText,
                    tableState.rowsPerPage
                  );
                  break;
                case "sort":
                  // setrowsPerPage(tableState.rowsPerPage)
                  // sort(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                  setrowsPerPage(tableState.rowsPerPage);
                  filterChange(
                    tableState.page,
                    tableState.sortOrder,
                    tableState.searchText,
                    tableState.rowsPerPage
                  );
                  break;
                case "search":
                  // setrowsPerPage(tableState.rowsPerPage)
                  // search(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                  setrowsPerPage(tableState.rowsPerPage);
                  setSearchText(tableState.searchText);
                  clearTimeout(timer);
                  const newTimer = setTimeout(() => {
                    filterChange(
                      tableState.page,
                      tableState.sortOrder,
                      tableState.searchText,
                      tableState.rowsPerPage
                    );
                  }, 1000);
                  setTimer(newTimer);
                  break;
                case "filterChange":
                  setrowsPerPage(tableState.rowsPerPage);
                  filterChange(
                    tableState.page,
                    tableState.sortOrder,
                    tableState.searchText,
                    tableState.rowsPerPage
                  );
                  break;
                case "changeRowsPerPage":
                  setrowsPerPage(tableState.rowsPerPage);
                  filterChange(
                    tableState.page,
                    tableState.sortOrder,
                    tableState.searchText,
                    tableState.rowsPerPage
                  );

                  break;
                default:
              }
            },
          }}
        />
        <SessionExpiredModal open={openSessionExpiredModal} />
      </ThemeProvider>
    </Page>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(UsersData);
