import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { Container, TextField, Grid, Typography, InputAdornment, Box,Card, RadioGroup, Radio, FormControlLabel, FormHelperText, FormControl, FormLabel, Tooltip } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from './../../images/loading-spinner.gif'
import SessionExpiredModal from "./../../components/AlertModal/SessionExpiredModal"
import { securityService } from "../../_services/security";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { orderService } from "./../../_services/orders";
import { userService } from "../../../src/_services/users";
import { connect } from "react-redux";
import  CopytoClipboard from "./../../components/ButtonComponents/CopytoClipboard";
import {Zoom} from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  zeroPadding: {
    padding: 0,
  },
  cardBorderRadius: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },
  cardHeaderMargin: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },
  fileInput: {
    display: "none",
  },
  imageSizes: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  container: {
    justifyContent: "center",
    justifyItems: "center",
    // marginTop: "10%",
  },
  label: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  marginInputLabel: {
    marginBottom: theme.spacing(3),
  },
  popDownBtn: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    // float: "left",
    textTransform:'none',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    // marginBottom: "10%",
  },
  typography: {
    display: "flex",
    alignItems: "center",
  },
  grid: {
    // display: "flex",
    alignItems: 'end'
  },
  resendBtnMargin: {
    marginTop: theme.spacing(3),
  },
  inputMargin: {
    marginBottom: theme.spacing(2),
  },
  paddingInput: {
    padding: "0px 0px",
  },
  buttonProgress: {
    marginRight: '4px'
  },
}));


const OrderCreateForm = (props) => {
  const classes = useStyles();
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [copy, setCopy] = React.useState(false)
  const [securityData, setSecurityData] = React.useState([])
  const [userData, setUserData] = React.useState([])
  const [selfData, setSelfData] = React.useState()
  const [paymentLink, setPaymentLink] = React.useState()
  const [maxLimit, setMaxLimit] = React.useState()
  const userCode = props?.authReducer?.auth.user?.related_partner_code
  const userId = props?.authReducer?.auth.user?.id
  const userName = props?.authReducer.auth.user?.display_name
  const name = props?.authReducer?.auth.user?.display_name
  const role = props?.authReducer?.auth?.user?.default_role_code
  const [initValues, setInitialValues] = useState({
    scripName: props?.data ? props.data : "",
    qty: "",
    price: props?.data ? props.data.price : 0,
    total: 0,
    userType: role === 'PARTNER_CLIENT' ? 'self' : 'client',
    user: '',
    upi:'',
    customPrice: '',
    commissionValue: '',
    others: '',
    paymentType: 'unlistedkart',
    isPriceOverridden: false
  })

  const upiArray =['Gpay','Phonepe','Paytm','Amazonpay','Bharatpe']
  const paymentAction = async (values) => {
    const price = parseFloat(values.price).toFixed(3)
    const amount = (+price + ((values.price * values.commissionValue) / 100))
    const data = {
      "userid": values.userType === 'self' ? userId : values.user.id,
      "amount":values.userType === 'self' ? values.price : amount,
      "paid_through":values.paymentType === "upi"? values.upi: values.paymentType === "others" ? values.others : values.paymentType,
      "paid_using_ulk_razorpay": values.paymentType === "unlistedkart" ? true : false,
      "has_price_overridden": values.commissionValue > maxLimit ? true : false,
      "overridden_percentage": values.commissionValue,
      "notes": {
        "buy_quantity": values.qty,
        "contact_no": values.userType === 'self' ? selfData.login_mobile_no : values.user.contact,
        "email": values.userType === 'self' ? selfData.email : values.user.email,
        "name": values.userType === 'self' ? name : values.user.name,
        "payable_price": values.total,
        "actual_price": values.price,
        "commission_value": values.price,
        "custom_price": values.userType === 'self' ? values.price : amount,
        "security_id": values.scripName.id,
        "type": "BUY_SECURITY"
      }
    }

   try{
    orderService.createServiceOrder(data).then((res) => {
      if (res.status === 200) {
        if (res.data.is_order_onHold) {
          props.formSubmit("request")
        } else {
          setPaymentLink(res.data.data.short_url)
          props.formSubmit("success")
        }
      }else if(res.status === 401){
        props.formSubmit(res.data.message)
      }
      else {
        props.formSubmit("failed")
      }
    })
   }catch{
    props.formSubmit("failed")
   }

  };


  const getAllSecurity = (() => {
    securityService.getSecurity()
      .then(res => {
        if (res) {
          if (res.status === 200) {
            let array = []
            res.data.data.map((item) => {
              array.push({
                id: item.id,
                name: item.name,
                price: item.price,
                logo:item.logo_url
              })
            })
            setInitialValues({
              ...initValues,
              commissionValue: res.data.max_sell_percentage
            })
            setMaxLimit(res.data.max_sell_percentage)
            setSecurityData(array)
            setLoading(false)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
              setLoading(false)
            }
          }

        }
      }).catch(error => console.log(error))
  })



  useEffect(() => {
    getAllSecurity()
    getAllUsers()
    getBasicInfo()
  }, [])

  const getAllUsers = (async () => {
    let data = await userService.getUsers(0, 0, '', 'All', userCode)
    if (data.status === 200) {
      let array = []
      data.data.map((item) => {
        array.push({
          id: item.id,
          name: item.name,
          email: item.email,
          contact: item.user_name
        })
      })
      setUserData(array)
    }
  })

  const getBasicInfo = () => {
    userService.getUserDetails(userId).then((res) => {
      if (res) {
        if (res.status === 200) {
          setSelfData(res.data[0]);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {

            setOpenSessionExpiredModal(true);
          } else {
            console.log(res)
          }
        }
      }
    });
  }

  let text = ""
  const copyText =((value)=>{
    setCopy(true)
    navigator.clipboard.writeText(value)
  })
  const CopyText =((value)=>{
    return(
      <Tooltip title={copy?"Copied": "Click to Copy"}  style={{cursor:'copy'}} onClick={()=> copyText(value)}>
        <Typography onMouseLeave={()=>setCopy(false)}  variant="span">{value}</Typography>
        </Tooltip> 
    )
  })
  return (
    <Container>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          qty: Yup.number().required('Required').test(
            'Is positive?',
            'Value must be greater than 0',
            (value) => value > 0
          ),
          userType: Yup.string().nullable().required("Required"),
          user: Yup.object().nullable().when("userType", {
            is: 'client',
            then: Yup.object().nullable()
              .required("Client is required"),
            otherwise: Yup.object().nullable(),
          }),
          scripName: Yup.object().nullable().required('Scrip  is required'),
          customPrice: Yup.string(),
          commissionValue: Yup.number().when("userType", {
            is: 'client',
            then: Yup.number().test(
              'Is positive?',
              'Value must be greater than 0',
              (value) => value > 0
            ).required("Commission type is required"),
            otherwise: Yup.number().nullable(),
          }),
          paymentType: Yup.string().required('Payment type is required'),
          others: Yup.string().nullable().when("paymentType", {
            is: 'others',
            then: Yup.string().nullable().required("Required"),
            otherwise: Yup.string().nullable(),
          }), 
          upi: Yup.string().nullable().when("paymentType", {
            is: 'upi',
            then: Yup.string().nullable().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          total: Yup.string().test(
            "Total",
            "Total must be <= 5L",
            value => value && value < 500000
          )
        })}
        onSubmit={(values) => {
          setLoading(true)
          paymentAction(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {!paymentLink ?
             <Grid container  className={classes.container}>
              <Grid item xs={12} md={8}>
            <Zoom in={values} timeout={500} /* Add other props as needed */>
              
              <Grid container spacing={1} columnSpacing={1} style={{width:'70%', margin:'auto'}}>
                {role !== 'PARTNER_CLIENT' && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl component="fieldset" color="primary" style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                    <Typography>Create order for</Typography>
                    <RadioGroup
                    style={{marginLeft:'10px'}}
                      aria-label="userType"
                      name="userType"
                      color="primary"
                      value={values.userType}
                      onChange={(e) => {
                        setFieldValue('userType', e.target.value)
                        setFieldValue('qty', '')
                        setFieldValue('total', 0)
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value={"self"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"Self"}
                        />
                        <FormControlLabel
                          value={"client"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"Client"}
                        />
                      </div>
                    </RadioGroup>
                    <FormHelperText style={{ color: "#F44336" }}>
                      {errors.userType &&
                        touched.userType &&
                        errors.userType}
                    </FormHelperText>
                  </FormControl>
                </Grid>}
                {values.userType === "client" &&
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography style={{padding:'4px 0'}}>Select Client*</Typography>
                    <Autocomplete
                      id="selectrole-role"
                      options={userData}
                      size="small"
                      getOptionLabel={(option) => option.name || " "}
                      value={values.user}
                      onChange={(e, value) => {
                        setFieldValue("user", value);

                      }}
                      renderInput={(params) => (
                        <TextField
                        size="small"
                          {...params}
                          error={Boolean(
                            touched.user && errors.user
                          )}
                          helperText={
                            touched.user && errors.user
                          }
                          name="user"
                          // label="Select User*"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                }
                <Grid item xs={12} sm={12} md={ values.userType==="self" ? 12 : 6} >
                <Typography style={{padding:'4px 0'}}>Select Scrip*</Typography>
                  <Autocomplete
                    id="scripName"
                    options={securityData}
                    getOptionLabel={(option) => option.name || " "}
                    value={values?.scripName}
                    onChange={(e, value) => {
                      setFieldValue("scripName", value);
                      setFieldValue("price", value?.price);
                      setFieldValue("qty", '');
                      setFieldValue("total", '0');
                    }}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        // label="Scrip Name*"
                        variant="outlined"
                        name="scripName"
                        error={Boolean(
                          touched.scripName &&
                          errors.scripName
                        )}
                        helperText={touched.scripName && errors.scripName}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.grid}  >
                <Typography style={{padding:'4px 0'}}>Landing Price</Typography>
                  <TextField
                  fullWidth
                  size="small"
                    id="qty"
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      readOnly: true,
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      readOnly: true,
                    }}

                    name="price"
                    value={values.price}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={1} lg={1} className={classes.grid} >
                  <Typography variant={"h3"} className={classes.typography}  >*</Typography>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography style={{padding:'4px 0'}}>Quantity*</Typography>
                  <TextField
                    fullWidth
                    id="qty"
                    size="small"
                    // label="Quantity"
                    type="number"
                    name="qty"
                    variant="outlined"
                    disabled={values.scripName === ""}
                    value={values.qty}
                    error={Boolean(touched.qty && errors.qty)}
                    helperText={touched.qty && errors.qty}
                    onChange={(e) => {
                      setFieldValue("qty", e.target.value);
                      if (values.userType === 'self') {
                        setFieldValue("total", parseFloat(values.price * e.target.value).toFixed(2));
                      } else {
                        const price = parseFloat(values.price).toFixed(3)
                        setFieldValue("total", parseFloat((+price + ((values.price * values.commissionValue) / 100)) * e.target.value).toFixed(2));
                      }
                    }}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                {values.userType === "client" &&
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography style={{padding:'4px 0'}}>Percentage</Typography>

                      <TextField
                        id="qty"
                        size="small"
                        // label={''}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
                        }}
                        onChange={(e) => {
                          setFieldValue('commissionValue', e.target.value)
                          const price = parseFloat(values.price).toFixed(3)
                          const sum = (+price + ((values.price * e.target.value) / 100))
                          setFieldValue("total", parseFloat(sum * values.qty).toFixed(2));
                        }}
                        error={Boolean(touched.commissionValue && errors.commissionValue)}
                        helperText={touched.commissionValue && errors.commissionValue}
                        fullWidth
                        variant="outlined"
                        name="commissionValue"
                        value={values.commissionValue}
                      />
                    </Grid>
                  </>
                }
              
                {touched.total && errors.total && <Typography style={{ margin: '4px' }}>
                 <b> Note:</b> <Typography color="error" component="span"> Total value should not be greater than 5 Lakhs as razorpay limit is exceeded  </Typography>
                </Typography>}
                {parseFloat(values.commissionValue) > maxLimit && values.userType !== "self" && <Typography style={{ margin: '4px' }}>
                 <b> Note:</b> <Typography color="error" component="span"> Price overridden need an approval from Unlistedkart  </Typography>
                </Typography>}
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '1rem' }}>
                  <FormControl component="fieldset" >
                    <FormLabel>Payment Method</FormLabel>
                    <RadioGroup
                      aria-label="paymentType"
                      name="paymentType"
                      color="primary"
                      value={values.paymentType}
                      onChange={handleChange}
                    >
                      <div>
                        <FormControlLabel
                          value={"unlistedkart"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"ULK Razorpay"}
                        />
                        <FormControlLabel
                          value={"imps"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"IMPS"}
                        />
                         <FormControlLabel
                          value={"upi"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"UPI"}
                        />
                        <FormControlLabel
                          value={"others"}
                          color="primary"
                          control={<Radio color="primary" />}
                          label={"Others"}
                        />
                      </div>
                    </RadioGroup>
                    <FormHelperText style={{ color: "#F44336" }}>
                      {errors.paymentType && touched.paymentType &&
                        errors.paymentType}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {values.paymentType === "imps" ?
                <>
                <Card item xs={12} style={{  boxShadow:`1px 1px 10px ${props.themeMode ? '#292a2c' :'lightGrey'}`, minHeight:'5rem',width:'100%', padding:'1rem' }}>
                  <Typography style={{display:'flex'}}><p style={{width:'150px'}}>Name :</p> {CopyText('Unlistedkart')} </Typography>
                  <Typography style={{display:'flex'}}><p style={{width:'150px'}}>Account Number :</p> {CopyText('8878776575')} </Typography>
                  <Typography style={{display:'flex'}}><p style={{width:'150px'}}>IFSC :</p> {CopyText('HDFC0002778')} </Typography>
                </Card>
                <Typography style={{margin:'8px 4px 1rem'}}>
                  <b>Note:</b> Use the above payment details to complete payment through Bank/UPI and share the payment proof.
                </Typography>
                </> :values.paymentType === "others" ? 
                <Grid item xs={12}>
                    <Typography style={{padding:'4px 0'}}>Others*</Typography>
                  <TextField
                    id="qty"
                    // label={'Others'}
                    size="small"

                    onChange={handleChange}
                    error={Boolean(touched.others && errors.others)}
                    helperText={touched.others && errors.others}
                    fullWidth
                    variant="outlined"
                    name="others"
                    value={values.others}
                  />
                </Grid> : values.paymentType === "upi" ?        
                <Grid item xs={12} >
                    <Typography style={{padding:'4px 0'}}>UPI Method*</Typography>
                <Autocomplete
                    id="scripName"
                    options={upiArray}
                    getOptionLabel={(option) => option || " "}
                    value={values?.upi}
                    onChange={(e, value) => {
                      setFieldValue("upi", value);
                    }}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        // label="UPI Option*"
                        variant="outlined"
                        name="upi"
                        error={Boolean(
                          touched.upi &&
                          errors.upi
                        )}
                        helperText={touched.upi && errors.upi}
                      />
                    )}
                  />
                  </Grid>
                : null}
                
               
              </Grid>
</Zoom>
</Grid>
<Grid item xs={12} md={4} className={classes.grid}
                  style={{ justifyContent: 'center'}}
                >
                  <Typography style={{fontWeight:'bold', padding:'4px'}}>Order Summary</Typography>
                  <Card direction="row" style={{minHeight:'5rem', padding:'1rem', textAlign:"end",boxShadow:'unset',border:'1px solid #e1e1e1', borderRadius:'10px' }}>
                  {values.scripName ? <img width={100} style={{textAlign:'center'}} src={values.scripName.logo} alt=""/> : null}
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography> Scrip Name  </Typography> 
                  <Typography> <b>{values.scripName ? values.scripName.name : "--"}</b></Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography> Investor Name  </Typography> 
                  <Typography> <b>{values.userType ==="self"  ?   userName : values.user ? values.user.name : '--'}</b></Typography>
                  </Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography> Price per share  </Typography> 
                  <Typography> <b>{values.userType ==="self" ? `₹${values.price}` : ` ₹${parseFloat((+parseFloat(values.price).toFixed(3) + ((values.price * values.commissionValue) / 100))).toFixed(2)}`}</b></Typography>
                  </Box>
               { values.userType==="self"? null :
                <>
                 <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography> Commission per share  </Typography> 
                  <Typography> <b>{values.userType ==="self" ? `₹${values.price}` :  `₹${(parseFloat((+parseFloat(values.price).toFixed(3) + ((values.price * values.commissionValue) / 100))).toFixed(2) - values.price).toFixed(2)}`}</b></Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography>Total Commission</Typography> 
                  <Typography> <b>₹{values.userType ==="self" ? values.price :  (values.total-(values.price*values.qty)).toFixed(2)}</b></Typography>
                  </Box>
                </>
                  }
                    <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography> Total Quantity </Typography> 
                  <Typography>  <b>{values.qty  ? values.qty : '--'}</b></Typography>
                    </Box>
                 
                    <Box display={'flex'} justifyContent={'space-between'} style={{borderTop:'1px solid lightGrey ',borderBottom:'1px solid lightGrey ', padding:'10px 0', marginTop:'10px'}}>
                    <Typography><b>Total price </b></Typography> 
                  <Typography>  <b>{values.total ? `₹${values.total}` : '--'}</b></Typography>
                    </Box>
                   </Card>
                   <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.popDownBtn}
                    size="small"
                    type="submit"
                  style={{background:'#314B8F',color:'#fff'}}
                  >
                    {loading === false ? <> {parseFloat(values.commissionValue) > maxLimit ? "Request Appoval" : "Create Order"}</> : <><img src={loadingSpinner} className={classes.buttonProgress} />  {parseFloat(values.commissionValue) > maxLimit ? "Request Appoval" : "Create Order"} </>}
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.popDownBtn}
                    size="small"
                    // type="submit"
                    onClick={props.onClose}
                    variant="outlined"
                    // variant="contained"
                  >
                    Cancel
                  </Button> 
                
                </Grid>
                </Grid>
                
            </Grid> :
              <Container >
                <Box style={{ display: 'flex', justifyContent: "center", margin: '1rem 0' }}>
                  Payment Link :  <a id="myInput" href={paymentLink} style={{ margin: "0 4px", }} target="_blank" >{paymentLink}</a>
                  <CopytoClipboard
                    userBasicInfo={(text = paymentLink)}
                    state={true}
                  />

                </Box>
              </Container>}
          </form>
        )}
      </Formik>
      <SessionExpiredModal
        open={openSessionExpiredModal}

      />
    </Container>
  )
}

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps)(OrderCreateForm);